import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  applicationForm as commonApplicationForm,
  applicationFormDefaultProps as commonApplicationFormDefaultProps, documentTypes,
} from 'visa-frontend-common/src/components/ApplicationForm/commonApplicationModel';
import {
  formArrayFieldDefaultProps,
  formFieldDefaultProps,
  formFieldDefaultValue,
  formFieldShape,
  formFieldShapeFor,
  formFileFieldShape,
  formTabDefaultProps,
  formTabShape,
} from 'visa-frontend-common/src/dataModel';
import {
  applicationFormTabs,
  applicationFormTabsDefaultProps,
} from 'visa-frontend-common/src/components/ApplicationForm/applicationFormDataModel';

export const ovafFormDefaultProps =
  R.mergeRight(
    commonApplicationFormDefaultProps,
    {
      residenceInOtherCountry: formFieldDefaultValue(null),
      fingerprintsAlreadyStored: formFieldDefaultValue(null),
      espLocation: formFieldDefaultProps,
      checklistName: formFieldDefaultProps,
      applicationChecklistItems: formArrayFieldDefaultProps,
      groupId: formFieldDefaultProps,
    },
  );

export const ovafApplicationFormShape = PropTypes.exact(
  R.mergeRight(
    commonApplicationForm,
    {
      espLocation: formFieldShapeFor(PropTypes.string).isRequired,
      checklistName: formFieldShapeFor(PropTypes.string).isRequired,
      applicationChecklistItems: formFieldShape.isRequired,
      groupId: formFieldShape.isRequired,
    },
  ),
);

export const ovafApplicationFormTabsShape = PropTypes.exact(
  R.mergeRight(
    applicationFormTabs,
    {
      applicationDocuments: formTabShape.isRequired,
    },
  ),
);

export const ovafApplicationFormTabsDefaultProps =
  R.mergeRight(
    applicationFormTabsDefaultProps,
    {
      applicationDocuments: formTabDefaultProps,
    },
  );

export const applicationStatus = {
  DRAFT: 'DRAFT',
  DONE: 'DONE',
  IN_PROCESSING: 'IN_PROCESSING',
};

export const applicationListItem = {
  applicationId: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  modifiedAt: PropTypes.string.isRequired,
  finalizedAt: PropTypes.string,
  printedAt: PropTypes.string,
  firstName: PropTypes.string,
  status: PropTypes.oneOf(R.values(applicationStatus)).isRequired,
  surname: PropTypes.string,
  travelDocumentNumber: PropTypes.string,
  vurn: PropTypes.string.isRequired,
  espLocation: PropTypes.string,
};

export const applicationListItemShape = PropTypes.exact(applicationListItem);

export const completedApplicationInformation = {
  applicationId: PropTypes.string.isRequired,
  vurn: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  surname: PropTypes.string,
  espLocation: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  modifiedAt: PropTypes.string.isRequired,
  finalizedAt: PropTypes.string,
  status: PropTypes.oneOf(R.values(applicationStatus)).isRequired,
};

export const completedApplicationShape = PropTypes.exact(completedApplicationInformation);

export const languagesWithExtraGuides = ['th', 'id', 'fa', 'fr', 'sq', 'ar', 'tr', 'ne', 'vi', 'uk', 'zh',
  'es', 'pt', 'gr'];

export const languageSelectorOptions = [
  { value: 'en_GB', label: 'EN - English' },
  { value: 'ru_RU', label: 'RU - Русский' },
  { value: 'fi_FI', label: 'FI - Suomi' },
  { value: 'sv_SE', label: 'SV - Svenska' },
  { value: 'ar_AR', label: 'AR - English with instructions in Arabic' },
  { value: 'es_ES', label: 'ES - English with instructions in Spanish' },
  { value: 'fa_IR', label: 'FA - English with instructions in Farsi' },
  { value: 'fr_FR', label: 'FR - English with instructions in French' },
  { value: 'gr_GR', label: 'GR - English with instructions in Greek' },
  { value: 'id_ID', label: 'ID - English with instructions in Bahasa Indonesia' },
  { value: 'ne_NP', label: 'NE - English with instructions in Nepali' },
  { value: 'pt_PT', label: 'PT - English with instructions in Portuguese' },
  { value: 'sq_AL', label: 'SQ - English with instructions in Albanian' },
  { value: 'th_TH', label: 'TH - English with instructions in Thai' },
  { value: 'tr_TR', label: 'TR - English with instructions in Turkish' },
  { value: 'uk_UA', label: 'UK - English with instructions in Ukrainian' },
  { value: 'vi_VN', label: 'VI - English with instructions in Vietnamese' },
  { value: 'zh_CN', label: 'ZH - English with instructions in Chinese' },
];

export const checklistPageLanguageSelectorOptions = [
  { value: 'en_GB', label: 'EN - English' },
  { value: 'ru_RU', label: 'RU - Русский' },
  { value: 'fi_FI', label: 'FI - Suomi' },
  { value: 'sv_SE', label: 'SV - Svenska' },
];

export const featureFlags = {
  S3DocumentUpload: 'S3DocumentUpload',
};

export const applicationFormFieldsPerTab = {
  personalDetails: [
    'firstName',
    'surname',
    'surnameAtBirth',
    'formerSurnames',
    'formerSurnamesInput',
    'patronymicName',
    'dateOfBirth',
    'placeOfBirth',
    'countryOfBirth',
    'currentNationality',
    'nationalityAtBirth',
    'otherNationalities',
    'sex',
    'maritalStatus',
    'maritalStatusSpecify',
    'nationalIdentityNumber',
    'guardianSurname',
    'guardianFirstName',
    'guardianNationality',
    'guardianAddress',
    'guardianAddressIsDifferentThanApplicants',
    'guardianEmail',
    'guardianPhonenumber',
    'secondaryGuardianSurname',
    'secondaryGuardianFirstName',
    'secondaryGuardianNationality',
    'secondaryGuardianAddress',
    'secondaryGuardianAddressIsDifferentThanApplicants',
    'secondaryGuardianEmail',
    'secondaryGuardianPhonenumber',
    'euFirstname',
    'euSurname',
    'euDateOfBirth',
    'euPassportNumber',
    'euFamilyRelationshipCode',
    'euFamilyRelationshipSpecify',
    'euNationality',
    'eucitizenFamily',
  ],
  travelDocuments: [
    'travelDocumentType',
    'travelDocumentTypeSpecify',
    'travelDocumentNumber',
    'travelDocumentIssueDate',
    'travelDocumentValidUntil',
    'travelDocumentIssuedBy',
    'travelDocumentCountryIssued',
  ],
  occupationAndResidence: [
    'address',
    'email',
    'phonenumbers',
    'phonenumbersInput',
    'residenceInOtherCountry',
    'residencePermitNumber',
    'residencePermitValidUntil',
    'occupationCode',
    'occupationOther',
    'employerName',
    'schoolName',
    'employerAddress',
    'employerEmail',
    'employerPhonenumber',
  ],
  journeyDetails: [
    'borderOfFirstEntryCountry',
    'dateOfArrival',
    'dateOfDeparture',
    'destinationStates',
    'journeyPurposeOther',
    'journeyPurposeDetails',
    'mainPurposeOfJourney',
    'otherPurposesOfJourney',
    'numberOfEntriesRequested',
    'fingerprintsAlreadyStored',
    'fingerprintsStoredAt',
    'fingerprintsVisaNumber',
    'entryPermitForFinalDestination',
    'entryPermit',
  ],
  contactsAndAccommodation: [
    'visaTypeRequested',
    'contactOrAccommodation',
    'invitingOrganization',
    'invitingOrganizationContact',
    'invitingPersons',
    'accommodations',
    'oneDayTripWithoutAccommodation',
    'travelCostsApplicantInUse',
    'travelCostsApplicant',
    'travelCostApplicantOther',
    'travelCostsSponsorInUse',
    'sponsorHost',
    'sponsorHostSpecify',
    'sponsorOther',
    'sponsorOtherSpecify',
    'travelCostsSponsor',
    'travelCostSponsorOther',
    'filledByFirstName',
    'filledBySurname',
    'filledByAddress',
    'filledByEmail',
    'filledByPhonenumber',
    'filledByOtherThanApplicant',
  ],
  applicationDocuments: [
    'espLocation',
    'checklistName',
    'applicationChecklistItems',
  ],
};

export const checklistShape = PropTypes.exact({
  checklistId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const staticChecklistDocumentShape = PropTypes.exact({
  documentType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const staticChecklistDocumentCategoryShape = PropTypes.exact({
  description: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(staticChecklistDocumentShape).isRequired,
});

export const staticChecklistShape = PropTypes.exact({
  checklistId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  documentCategories: PropTypes.arrayOf(staticChecklistDocumentCategoryShape).isRequired,
});

export const checklistItemAttachmentShape = PropTypes.exact({
  checklistId: PropTypes.number.isRequired,
  checklistItemAttachmentId: PropTypes.number.isRequired,
  checklistItemType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orderNumber: PropTypes.number.isRequired,
  attachmentKey: PropTypes.string.isRequired,
});

export const applicationChecklistItemFormWithMetadata = {
  checklistItemType: formFieldShape.isRequired,
  description: formFieldShape.isRequired,
  applicantChecked: formFieldShape.isRequired,
  attachmentTypeDescription: PropTypes.string,
  attachmentName: PropTypes.string,
};

export const applicationGroupShape = PropTypes.exact({
  groupId: PropTypes.number.isRequired,
  status: PropTypes.oneOf(R.values(applicationStatus)).isRequired,
  applications: PropTypes.arrayOf(applicationListItemShape),
});

export const applicationGroupFormShape = PropTypes.exact({
  applicationId: formFieldShape.isRequired,
  groupId: formFieldShape.isRequired,
});

export const applicationDetail = {
  HOME_ADDRESS: 'HOME_ADDRESS',
  EU_RELATIVE: 'EU_RELATIVE',
  JOURNEY: 'JOURNEY',
  INVITATIONS: 'INVITATIONS',
};

export const applicationCopyFormShape = PropTypes.exact({
  applicationId: formFieldShape.isRequired,
  groupId: formFieldShape.isRequired,
  details: formFieldShape.isRequired,
});

export const pendingRequestStatuses = {
  NEW: 'NEW',
  OPEN: 'OPEN',
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  READY: 'READY',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  CLOSED: 'CLOSED',
};

export const pendingRequestDocumentStatuses = {
  // NEW, PROCESSED not used in frontend
  ACCEPTED: 'ACCEPTED',
  ERROR: 'ERROR',
  REJECTED: 'REJECTED',
};

export const pendingRequestSources = {
  MFA: 'MFA',
  ESP: 'ESP',
  MISSION: 'MISSION',
  OVAF: 'OVAF',
};

export const pendingRequestReasons = {
  APPLICATION_FINGERPRINTS: 'APPLICATION_FINGERPRINTS',
  APPLICATION_FACIAL_IMAGE: 'APPLICATION_FACIAL_IMAGE',
  APPLICATION_PAYMENT_CORRECTION: 'APPLICATION_PAYMENT_CORRECTION',
  APPLICATION_REQUEST_TO_APPLICANT: 'APPLICATION_REQUEST_TO_APPLICANT',
  DOCUMENT_MISSING: 'DOCUMENT_MISSING',
  DOCUMENT_SCAN_QUALITY: 'DOCUMENT_SCAN_QUALITY',
  DOCUMENT_CONTENT: 'DOCUMENT_CONTENT',
  DOCUMENT_OTHER_REQUEST: 'DOCUMENT_OTHER_REQUEST',
};

export const pendingDeliveryTargets = [
  'ESP',
  'MFA',
];

export const checklistItemTypeShape = PropTypes.exact({
  checklistItemType: PropTypes.oneOf(documentTypes).isRequired,
  attachmentName: PropTypes.string.isRequired,
  attachmentTypeDescription: PropTypes.string.isRequired,
});

export const pendingRequestShape = PropTypes.exact({
  pendingRequestId: PropTypes.string.isRequired,
  applicationId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(R.values(pendingRequestStatuses)).isRequired,
  source: PropTypes.oneOf(R.values(pendingRequestSources)),
  reason: PropTypes.string.isRequired,
  checklistItemType: PropTypes.string,
  dueDate: PropTypes.string,
  notes: PropTypes.string,
  receivedAt: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  attachmentsAllowed: PropTypes.bool.isRequired,
  vurn: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  travelDocumentNumber: PropTypes.string.isRequired,
  espLocation: PropTypes.string.isRequired,
  deliveryTarget: PropTypes.oneOf(pendingDeliveryTargets),
});

export const pendingAnswerType = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  READY: 'READY',
};

export const pendingClosedType = {
  CLOSED: 'CLOSED',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
};

export const answerFormShape = PropTypes.exact({
  answer: formFieldShape.isRequired,
});

export const statusShape = formFieldShapeFor(
  PropTypes.oneOf(R.keys(pendingRequestDocumentStatuses)),
);

export const uploadFormShape = PropTypes.exact({
  documentFiles: formFieldShapeFor(PropTypes.arrayOf(PropTypes.exact({
    uid: formFieldShape,
    key: formFieldShape,
    status: statusShape,
    pages: formFieldShape,
    file: formFileFieldShape,
  }))).isRequired,
  uploadConfirmed: formFieldShape.isRequired,
});

export const documentCountShape = PropTypes.exact({
  pendingRequestId: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
});
