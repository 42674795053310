import React from 'react';
import * as R from 'ramda';

import PropTypes from 'prop-types';
import TwoColumnRow from '../UI/TwoColumnRow/TwoColumnRow';
import TabSubtitle from '../UI/TabSubtitle/TabSubtitle';
import { formAddressShape, formFieldShape, formFieldShapeFor } from '../../dataModel';
import {
  Input,
  InputGroup,
  inputProps,
  CheckboxGroup,
} from '../UI/Form/FormComponents';
import i18n from '../../services/i18n';
import {
  booleanToOption,
  optionToBoolean,
  transformEventValueBeforeHandle,
} from '../../utils/commonUtils';
import Address from './Address';

const FilledByPerson = (props) => {
  const textInputProps = inputProps('text', R.__, R.__, R.__, false, props.handleChange);

  const toggleOption = label => [
    {
      keyProp: 'YES',
      value: 'true',
      label,
    }];

  const handleFilledByOptionChange = (event) => {
    transformEventValueBeforeHandle(props.handleChange, optionToBoolean)(event);

    if (!optionToBoolean(event.target.value)) {
      props.clearFields([
        'form.filledByFirstName',
        'form.filledBySurname',
        'form.filledByAddress',
        'form.filledByEmail',
        'form.filledByPhonenumber',
      ]);
    }
  };

  return (
    <>
      <TabSubtitle id="applicationForm-filledBy-subtitle" type="h4">{`${i18n.t('common:applicationForm.filledByOtherThanApplicantSubtitle')} [33]`}</TabSubtitle>
      <div role="form" aria-labelledby="applicationForm-filledBy-subtitle">
        <CheckboxGroup
          name="filledByOtherThanApplicant"
          value={booleanToOption(props.filledByOtherThanApplicant.value)}
          options={toggleOption(i18n.t('common:applicationForm.filledByOtherThanApplicant'))}
          onChange={handleFilledByOptionChange}
        />

        <div className="mt-4" />

        { props.filledByOtherThanApplicant.value &&
          <>
            <TwoColumnRow oneColumn={props.oneColumn}>
              <InputGroup
                label={i18n.t('common:applicationForm.surname')}
                required
                validationError={props.filledBySurname.validationError}
                describedBy="contactsAndAccomodationGuide-contactInfoGuideForField33"
              >
                <Input {...textInputProps(props.filledBySurname.value, 'filledBySurname', null)} uppercase={props.uppercase} required />
              </InputGroup>

              <InputGroup
                label={i18n.t('common:applicationForm.firstName')}
                required
                validationError={props.filledByFirstName.validationError}
                describedBy="contactsAndAccomodationGuide-contactInfoGuideForField33"
              >
                <Input {...textInputProps(props.filledByFirstName.value, 'filledByFirstName', null)} uppercase={props.uppercase} required />
              </InputGroup>
            </TwoColumnRow>

            <Address
              address={props.filledByAddress}
              addressFieldName="filledByAddress"
              labels={{}}
              handleFieldChange={props.handleChange}
              codesetSelectContainer={props.codesetSelectContainer}
              oneColumn={props.oneColumn}
              describedBy="contactsAndAccomodationGuide-contactInfoGuideForField33"
              uppercase={props.uppercase}
            />

            <TwoColumnRow oneColumn={props.oneColumn}>
              <InputGroup
                label={i18n.t('common:applicationForm.email')}
                required
                validationError={props.filledByEmail.validationError}
                describedBy="contactsAndAccomodationGuide-contactInfoGuideForField33"
              >
                <Input {...textInputProps(props.filledByEmail.value, 'filledByEmail', null)} uppercase={props.uppercase} required />
              </InputGroup>

              <InputGroup
                label={i18n.t('common:applicationForm.phonenumber')}
                required
                validationError={props.filledByPhonenumber.validationError}
                describedBy="contactsAndAccomodationGuide-contactInfoGuideForField33"
              >
                <Input {...textInputProps(props.filledByPhonenumber.value, 'filledByPhonenumber', null)} uppercase={props.uppercase} required />
              </InputGroup>
            </TwoColumnRow>
          </>}
      </div>
    </>
  );
};

FilledByPerson.propTypes = {
  handleChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  filledByFirstName: formFieldShape.isRequired,
  filledBySurname: formFieldShape.isRequired,
  filledByAddress: formFieldShapeFor(formAddressShape).isRequired,
  filledByEmail: formFieldShape.isRequired,
  filledByPhonenumber: formFieldShape.isRequired,
  filledByOtherThanApplicant: formFieldShapeFor(PropTypes.bool).isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  uppercase: PropTypes.bool,
};

FilledByPerson.defaultProps = {
  oneColumn: false,
  uppercase: false,
};

export default FilledByPerson;
