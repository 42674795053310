import React from 'react';
import PropTypes from 'prop-types';

import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import { formAddressShape, formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import { invitationFormShape } from 'visa-frontend-common/src/components/ApplicationForm/Invitations/InvitationsDataModel';

import i18n from '../../../../services/i18n';
import paths from '../../../../paths';
import PreviewEditLink from './PreviewEditLink';
import PreviewInvitations from './PreviewInvitations';
import PreviewTravelCosts from './PreviewTravelCosts';
import PreviewFilledByPerson from './PreviewFilledByPerson';

const PreviewContactsAndAccommodation = (props) => {
  const contactsAndAccommodationPath = paths.application.tabs.contactsAndAccommodation;

  return (
    <div className="preview-application__section">
      <TabSubtitle type="h3" className="preview-application__subtitle">
        {i18n.t('application.tabs.contactsAndAccommodation')}
      </TabSubtitle>

      <PreviewInvitations
        contactOrAccommodation={props.contactOrAccommodation}
        invitingOrganization={props.invitingOrganization}
        invitingOrganizationContact={props.invitingOrganizationContact}
        invitingPersons={props.invitingPersons}
        accommodations={props.accommodations}
        oneDayTripWithoutAccommodation={props.oneDayTripWithoutAccommodation}
        eucitizenFamily={props.eucitizenFamily}
      />

      <PreviewTravelCosts
        travelCostsApplicantInUse={props.travelCostsApplicantInUse}
        travelCostsApplicant={props.travelCostsApplicant}
        travelCostApplicantOther={props.travelCostApplicantOther}
        travelCostsSponsorInUse={props.travelCostsSponsorInUse}
        sponsorHost={props.sponsorHost}
        sponsorHostSpecify={props.sponsorHostSpecify}
        sponsorOther={props.sponsorOther}
        sponsorOtherSpecify={props.sponsorOtherSpecify}
        travelCostsSponsor={props.travelCostsSponsor}
        travelCostSponsorOther={props.travelCostSponsorOther}
        invitingPersons={props.invitingPersons}
        invitingOrganization={props.invitingOrganization}
        eucitizenFamily={props.eucitizenFamily}
      />

      { props.filledByOtherThanApplicant.value &&
        <PreviewFilledByPerson
          filledBySurname={props.filledBySurname}
          filledByFirstName={props.filledByFirstName}
          filledByAddress={props.filledByAddress}
          filledByEmail={props.filledByEmail}
          filledByPhonenumber={props.filledByPhonenumber}
        />}

      <PreviewEditLink
        tabNameLabel={i18n.t('application.tabs.contactsAndAccommodation')}
        tabPath={contactsAndAccommodationPath}
      />
    </div>
  );
};

PreviewContactsAndAccommodation.propTypes = {
  contactOrAccommodation: formFieldShape.isRequired,
  invitingOrganization: formFieldShapeFor(invitationFormShape).isRequired,
  invitingOrganizationContact: formFieldShapeFor(invitationFormShape).isRequired,
  invitingPersons: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  accommodations: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  oneDayTripWithoutAccommodation: formFieldShape.isRequired,
  travelCostsApplicantInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  travelCostsApplicant: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostApplicantOther: formFieldShape.isRequired,
  travelCostsSponsorInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  sponsorHost: formFieldShape.isRequired,
  sponsorHostSpecify: formFieldShape.isRequired,
  sponsorOther: formFieldShape.isRequired,
  sponsorOtherSpecify: formFieldShape.isRequired,
  travelCostsSponsor: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostSponsorOther: formFieldShape.isRequired,
  eucitizenFamily: formFieldShapeFor(PropTypes.bool).isRequired,
  filledByFirstName: formFieldShape.isRequired,
  filledBySurname: formFieldShape.isRequired,
  filledByAddress: formFieldShapeFor(formAddressShape).isRequired,
  filledByEmail: formFieldShape.isRequired,
  filledByPhonenumber: formFieldShape.isRequired,
  filledByOtherThanApplicant: formFieldShapeFor(PropTypes.bool).isRequired,
};

export default PreviewContactsAndAccommodation;
