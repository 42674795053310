import React from 'react';

import { formAddressShape, formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';
import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import LabeledCode from '../../../../components/UI/labeledComponents/LabeledCode';
import PreviewValidationError from './PreviewValidationError';
import i18n from '../../../../services/i18n';
import paths from '../../../../paths';

const contactsAndAccommodationPath = paths.application.tabs.contactsAndAccommodation;

const PreviewFilledByPerson = (props) => {
  return (
    <>
      <TabSubtitle id="applicationForm-filledBy-subtitle" type="h3">{`${i18n.t('common:applicationForm.filledByOtherThanApplicantSubtitle')} [33]`}</TabSubtitle>

      { props.filledBySurname.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.surname')} *`}
          validationError={props.filledBySurname.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.surname')} *`}
          dataCy="labeled-filledBySurname"
          value={props.filledBySurname.value}
        />}

      { props.filledByFirstName.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.firstName')} *`}
          validationError={props.filledByFirstName.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.firstName')} *`}
          dataCy="labeled-filledByFirstName"
          value={props.filledByFirstName.value}
        />}

      { props.filledByAddress.value.street.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.street')} *`}
          validationError={props.filledByAddress.value.street.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.street')} [19] *`}
          dataCy="labeled-address-street"
          value={props.filledByAddress.value.street.value}
        />}

      { props.filledByAddress.value.zip.validationError ?
        <PreviewValidationError
          fieldNameLabel={i18n.t('common:applicationForm.zip')}
          validationError={props.filledByAddress.value.zip.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={i18n.t('common:applicationForm.zip')}
          dataCy="labeled-address-zip"
          value={props.filledByAddress.value.zip.value}
        />}

      { props.filledByAddress.value.city.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.city')} *`}
          validationError={props.filledByAddress.value.city.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.city')} *`}
          dataCy="labeled-address-city"
          value={props.filledByAddress.value.city.value}
        />}

      { props.filledByAddress.value.country.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.country')} *`}
          validationError={props.filledByAddress.value.country.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledCode
          labelText={`${i18n.t('common:applicationForm.country')} *`}
          dataCy="labeled-address-country"
          group="CountryOfNationality"
          code={props.filledByAddress.value.country.value}
        />}

      { props.filledByEmail.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.email')} *`}
          validationError={props.filledByEmail.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.email')} *`}
          dataCy="labeled-filledByEmail"
          value={props.filledByEmail.value}
        />}

      { props.filledByPhonenumber.validationError ?
        <PreviewValidationError
          fieldNameLabel={`${i18n.t('common:applicationForm.phonenumber')} *`}
          validationError={props.filledByPhonenumber.validationError}
          tabPath={contactsAndAccommodationPath}
        /> :
        <LabeledValue
          labelText={`${i18n.t('common:applicationForm.phonenumber')} *`}
          dataCy="labeled-filledByPhonenumber"
          value={props.filledByPhonenumber.value}
        />}
    </>);
};

PreviewFilledByPerson.propTypes = {
  filledByFirstName: formFieldShape.isRequired,
  filledBySurname: formFieldShape.isRequired,
  filledByAddress: formFieldShapeFor(formAddressShape).isRequired,
  filledByEmail: formFieldShape.isRequired,
  filledByPhonenumber: formFieldShape.isRequired,
};

export default PreviewFilledByPerson;
