import applicationPrintingStyles from './applicationPrintingStyles';

export const printHtmlInIframe = (htmlBody, styles) => {
  if (!document.getElementById('applicationPrintingFrame')) {
    const printIframe = document.createElement('iframe');
    printIframe.style.display = 'none'; // comment this if you want to see the application print in an iframe
    printIframe.style.width = '36.5%';
    printIframe.style.height = '6000px';
    printIframe.name = 'applicationPrintingFrame';
    printIframe.id = 'applicationPrintingFrame';
    document.body.append(printIframe);
  }
  const frame = window.frames.applicationPrintingFrame;
  frame.document.close();
  frame.document.write(`
  <html lang="fi">
    <head>
      <style>${styles}</style>
    </head>
    <body>
      ${htmlBody}
    </body>
  </html>`);
  frame.focus();
  frame.window.addEventListener('load', () => frame.print());
  frame.document.close();
  frame.window.focus();
  frame.window.close();
};

export const printApplicationInIframe = applicationBody => (
  printHtmlInIframe(applicationBody, applicationPrintingStyles)
);

export const printChecklistInIframe = checklistBody => (
  printHtmlInIframe(checklistBody, applicationPrintingStyles)
);
