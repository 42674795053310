import React from 'react';
import PropTypes from 'prop-types';

import Divider from 'visa-frontend-common/src/components/UI/Divider/Divider';

import PreviewApplicationDetails from './Presentation/PreviewApplicationDetails';
import PreviewPersonalDetails from './Presentation/PreviewPersonalDetails';
import PreviewTravelDocuments from './Presentation/PreviewTravelDocuments';
import PreviewOccupationAndResidence from './Presentation/PreviewOccupationAndResidence';
import PreviewJourneyDetails from './Presentation/PreviewJourneyDetails';
import PreviewContactsAndAccommodation from './Presentation/PreviewContactsAndAccommodation';
import PreviewApplicationDocuments from './Presentation/PreviewApplicationDocuments';
import { ovafApplicationFormShape, applicationChecklistItemFormWithMetadata } from '../../../ovafModel';

import './PreviewApplication.scss';

class PreviewApplication extends React.PureComponent {
  render() {
    return (
      <div className="preview-application">
        <PreviewApplicationDetails
          className="preview-application__section"
          travelAgencyName={this.props.form.travelAgencyName}
          visaTypeRequested={this.props.form.visaTypeRequested}
        />

        <Divider fullWidth />

        <PreviewPersonalDetails
          className="preview-application__section"
          surname={this.props.form.surname}
          surnameAtBirth={this.props.form.surnameAtBirth}
          formerSurnames={this.props.form.formerSurnames}
          firstName={this.props.form.firstName}
          dateOfBirth={this.props.form.dateOfBirth}
          placeOfBirth={this.props.form.placeOfBirth}
          countryOfBirth={this.props.form.countryOfBirth}
          currentNationality={this.props.form.currentNationality}
          nationalityAtBirth={this.props.form.nationalityAtBirth}
          otherNationalities={this.props.form.otherNationalities}
          sex={this.props.form.sex}
          maritalStatus={this.props.form.maritalStatus}
          maritalStatusSpecify={this.props.form.maritalStatusSpecify}
          nationalIdentityNumber={this.props.form.nationalIdentityNumber}
          guardianSurname={this.props.form.guardianSurname}
          guardianFirstName={this.props.form.guardianFirstName}
          guardianNationality={this.props.form.guardianNationality}
          guardianAddress={this.props.form.guardianAddress}
          guardianAddressIsDifferentThanApplicants={
            this.props.form.guardianAddressIsDifferentThanApplicants
          }
          guardianEmail={this.props.form.guardianEmail}
          guardianPhonenumber={this.props.form.guardianPhonenumber}
          secondaryGuardianSurname={this.props.form.secondaryGuardianSurname}
          secondaryGuardianFirstName={this.props.form.secondaryGuardianFirstName}
          secondaryGuardianNationality={this.props.form.secondaryGuardianNationality}
          secondaryGuardianAddress={this.props.form.secondaryGuardianAddress}
          secondaryGuardianAddressIsDifferentThanApplicants={
            this.props.form.secondaryGuardianAddressIsDifferentThanApplicants
          }
          secondaryGuardianEmail={this.props.form.secondaryGuardianEmail}
          secondaryGuardianPhonenumber={this.props.form.secondaryGuardianPhonenumber}
          eucitizenFamily={this.props.form.eucitizenFamily}
          euSurname={this.props.form.euSurname}
          euFirstname={this.props.form.euFirstname}
          euDateOfBirth={this.props.form.euDateOfBirth}
          euPassportNumber={this.props.form.euPassportNumber}
          euFamilyRelationshipCode={this.props.form.euFamilyRelationshipCode}
          euFamilyRelationshipSpecify={this.props.form.euFamilyRelationshipSpecify}
          euNationality={this.props.form.euNationality}
        />

        <Divider fullWidth />

        <PreviewTravelDocuments
          travelDocumentType={this.props.form.travelDocumentType}
          travelDocumentTypeSpecify={this.props.form.travelDocumentTypeSpecify}
          travelDocumentNumber={this.props.form.travelDocumentNumber}
          travelDocumentIssueDate={this.props.form.travelDocumentIssueDate}
          travelDocumentValidUntil={this.props.form.travelDocumentValidUntil}
          travelDocumentCountryIssued={this.props.form.travelDocumentCountryIssued}
        />

        <Divider fullWidth />

        <PreviewOccupationAndResidence
          address={this.props.form.address}
          secondaryAddress={this.props.form.secondaryAddress}
          email={this.props.form.email}
          phonenumbers={this.props.form.phonenumbers}
          residenceInOtherCountry={this.props.form.residenceInOtherCountry}
          residencePermitNumber={this.props.form.residencePermitNumber}
          residencePermitValidUntil={this.props.form.residencePermitValidUntil}
          occupationCode={this.props.form.occupationCode}
          occupationOther={this.props.form.occupationOther}
          employerName={this.props.form.employerName}
          schoolName={this.props.form.schoolName}
          employerAddress={this.props.form.employerAddress}
          employerPhonenumber={this.props.form.employerPhonenumber}
          employerEmail={this.props.form.employerEmail}
        />

        <Divider fullWidth />

        <PreviewJourneyDetails
          destinationStates={this.props.form.destinationStates}
          borderOfFirstEntryCountry={this.props.form.borderOfFirstEntryCountry}
          numberOfEntriesRequested={this.props.form.numberOfEntriesRequested}
          dateOfArrival={this.props.form.dateOfArrival}
          dateOfDeparture={this.props.form.dateOfDeparture}
          fingerprintsAlreadyStored={this.props.form.fingerprintsAlreadyStored}
          fingerprintsStoredAt={this.props.form.fingerprintsStoredAt}
          fingerprintsVisaNumber={this.props.form.fingerprintsVisaNumber}
          mainPurposeOfJourney={this.props.form.mainPurposeOfJourney}
          otherPurposesOfJourney={this.props.form.otherPurposesOfJourney}
          journeyPurposeDetails={this.props.form.journeyPurposeDetails}
          journeyPurposeOther={this.props.form.journeyPurposeOther}
          oneDayTripWithoutAccommodation={
            this.props.form.oneDayTripWithoutAccommodation
          }
          entryPermitForFinalDestination={
            this.props.form.entryPermitForFinalDestination
          }
          entryPermit={this.props.form.entryPermit}
        />

        <Divider fullWidth />

        <PreviewContactsAndAccommodation
          contactOrAccommodation={this.props.form.contactOrAccommodation}
          invitingOrganization={this.props.form.invitingOrganization}
          invitingOrganizationContact={this.props.form.invitingOrganizationContact}
          invitingPersons={this.props.form.invitingPersons}
          accommodations={this.props.form.accommodations}
          oneDayTripWithoutAccommodation={
            this.props.form.oneDayTripWithoutAccommodation
          }
          travelCostsApplicantInUse={this.props.form.travelCostsApplicantInUse}
          travelCostsApplicant={this.props.form.travelCostsApplicant}
          travelCostApplicantOther={this.props.form.travelCostApplicantOther}
          travelCostsSponsorInUse={this.props.form.travelCostsSponsorInUse}
          sponsorHost={this.props.form.sponsorHost}
          sponsorHostSpecify={this.props.form.sponsorHostSpecify}
          sponsorOther={this.props.form.sponsorOther}
          sponsorOtherSpecify={this.props.form.sponsorOtherSpecify}
          travelCostsSponsor={this.props.form.travelCostsSponsor}
          travelCostSponsorOther={this.props.form.travelCostSponsorOther}
          eucitizenFamily={this.props.form.eucitizenFamily}
          filledBySurname={this.props.form.filledBySurname}
          filledByFirstName={this.props.form.filledByFirstName}
          filledByAddress={this.props.form.filledByAddress}
          filledByEmail={this.props.form.filledByEmail}
          filledByPhonenumber={this.props.form.filledByPhonenumber}
          filledByOtherThanApplicant={this.props.form.filledByOtherThanApplicant}
        />

        <Divider fullWidth />

        <PreviewApplicationDocuments
          espLocation={this.props.form.espLocation}
          checklistName={this.props.form.checklistName}
          applicationChecklistForm={this.props.form.applicationChecklistItems}
          applicationChecklistItems={this.props.applicationChecklistItems}
          dateOfBirth={this.props.form.dateOfBirth}
        />
      </div>
    );
  }
}

PreviewApplication.propTypes = {
  form: ovafApplicationFormShape.isRequired,
  applicationChecklistItems:
    PropTypes.arrayOf(PropTypes.exact(applicationChecklistItemFormWithMetadata)),
};

PreviewApplication.defaultProps = {
  applicationChecklistItems: [],
};

export default PreviewApplication;
