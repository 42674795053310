import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  Route,
  Redirect,
  Switch,
  generatePath,
  matchPath,
} from 'react-router-dom';

import InlineNotification from 'visa-frontend-common/src/components/UI/InlineNotification/InlineNotification';
import { notifySuccess } from 'visa-frontend-common/src/services/pushNotificationService';
import { eventToField, handleChangeAndResetClarificationField } from 'visa-frontend-common/src/utils/commonUtils';
import LabeledValue from 'visa-frontend-common/src/components/UI/labeledComponents/LabeledValue';
import NavbarForTabs from 'visa-frontend-common/src/components/UI/NavbarForTabs/NavbarForTabs';
import Button from 'visa-frontend-common/src/components/UI/Button/Button';
import Divider from 'visa-frontend-common/src/components/UI/Divider/Divider';
import TabSubtitle from 'visa-frontend-common/src/components/UI/TabSubtitle/TabSubtitle';
import TwoColumnRow from 'visa-frontend-common/src/components/UI/TwoColumnRow/TwoColumnRow';
import PersonalDetails from 'visa-frontend-common/src/components/ApplicationForm/PersonalDetails';
import TravelDocuments from 'visa-frontend-common/src/components/ApplicationForm/TravelDocuments';
import JourneyDetails from 'visa-frontend-common/src/components/ApplicationForm/JourneyDetails';
import OccupationAndResidence from 'visa-frontend-common/src/components/ApplicationForm/OccupationAndResidence';
import FormTab from 'visa-frontend-common/src/components/UI/Form/FormTab/FormTab';
import { matchShape } from 'visa-frontend-common/src/dataModel';
import Form from 'visa-frontend-common/src/components/UI/Form/Form';

import Finalize from './Finalize';
import ApplicationDocuments from './ApplicationDocuments';
import paths from '../../paths';
import i18n from '../../services/i18n';
import actions from './ApplicationActions';
import Loading from '../../components/UI/Loading/LoadingContainer';
import Guide from '../../components/Guide/Guide';
import CodesetSelectContainer from '../../components/UI/Form/CodesetCode/CodesetSelectContainer';
import CodesetListFieldContainer from '../../components/UI/Form/CodesetCode/CodesetListFieldContainer';
import {
  checklistShape,
  languagesWithExtraGuides,
  ovafApplicationFormShape,
  ovafApplicationFormTabsShape,
  applicationChecklistItemFormWithMetadata,
  checklistItemAttachmentShape,
} from '../../ovafModel';

import PersonalDetailsGuide from './Guides/PersonalDetailsGuide';
import TravelDocumentsGuide from './Guides/TravelDocumentsGuide';
import OccupationAndResidenceGuide from './Guides/OccupationAndResidenceGuide';
import JourneyDetailsGuide from './Guides/JourneyDetailsGuide';
import ContactsAndAccommodationGuide from './Guides/ContactsAndAccommodationGuide';
import FinalizeGuide from './Guides/FinalizeGuide';
import EuFamilyRelationshipGuideWithExtras from './Guides/EuFamilyRelationshipGuideWithExtras';
import EuFamilyRelationshipGuide from './Guides/EuFamilyRelationshipGuide';
import ResidencePermitGuide from './Guides/ResidencePermitGuide';
import ResidencePermitGuideWithExtras from './Guides/ResidencePermitGuideWithExtras';
import FingerPrintsAndDestinationPermitGuide from './Guides/FingerprintsAndDestinationPermitGuide';
import FingerPrintsAndDestinationPermitGuideWithExtras from './Guides/FingeprintsAndDestinationPermitGuideWithExtras';
import ApplicationDocumentsGuide from './Guides/ApplicationDocumentsGuide';
import DocumentTitle from '../../components/DocumentTitle/DocumentTitle';
import rules from './ApplicationValidationRules';
import EuCitizen from './EuCitizen/EuCitizen';
import ContactsAndAccomodation from './ContactsAndAccomodation';
import LoadingButton from '../../components/UI/Button/LoadingButtonContainer';

const SaveButton = (props) => {
  const handleClick = (event) => {
    event.preventDefault();
    props.onClick(event);
  };
  return (
    <LoadingButton
      requestNames={[actions.applicationSent().type]}
      type="submit"
      onClick={handleClick}
      className="application__submit-button"
      icon="check"
      label={i18n.t('application.save')}
      dataCy="application-save-button"
      disabled={props.disabled}
    />);
};

SaveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SaveButton.defaultProps = {
  disabled: false,
};

const SendToDoneButton = (props) => {
  return (
    <Button
      color="green"
      className="application__submit-button"
      onClick={props.onClick}
      disabled={props.disabled}
      icon="playlist_play"
      label={i18n.t('application.sendToDone')}
      dataCy="application-send-button"
    />
  );
};

SendToDoneButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SendToDoneButton.defaultProps = {
  disabled: false,
};

class ApplicationForm extends React.Component {
  openModalAtStart = true;

  componentDidMount() {
    this.props.formCleared();
    if (this.props.pathId) {
      this.props.fetchApplication(this.props.pathId);
    } else {
      window.mtcaptchaConfig.renderQueue.push('mtcaptcha-2');
    }
    this.props.fetchChecklistItemTypes();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.pathId) {
      this.openModalAtStart = false;
    }
  }

  componentWillUnmount() {
    this.props.formCleared();
  }

  handleCreateDraft = () =>
    this.props.saveDraftApplication(
      this.props.form,
      this.props.match.params.tab,
      this.props.setDirty,
    );

  handleCreateDraftToDone = () =>
    this.props.saveDraftApplicationToDone(this.props.form, this.props.setDirty);

  handleUpdateDraft = () => this.props.updateDraftApplication(this.props.form, this.props.pathId)
    .then(R.unless(
      R.is(Error),
      () => {
        notifySuccess('application-draft-updated', i18n.t('common:saveSuccess'));
        this.props.setDirty(false);
      },
    ));

  handleUpdateDraftToDone = () =>
    this.props.updateDraftApplicationToDone(
      this.props.form,
      this.props.pathId,
      this.props.setDirty,
    );

  handleInput = (event) => {
    this.props.setFieldAndValidate(eventToField(event), this.props.form);
    this.props.setDirty(true);
  };

  updateListField = (listField, inputField) => {
    this.props.setDirty(true);
    const fields = R.mergeDeepRight(listField, inputField);
    this.props.setFieldAndValidate(fields, R.mergeDeepRight(this.props.form, fields));
  };

  handleSetEspLocation = (event) => {
    this.props.setEspLocation(eventToField(event), this.props.form, this.props.setDirty);
  };

  handleSetChecklist = (event, checklistId) => {
    this.props.setChecklist(
      eventToField(event),
      checklistId,
      this.props.form,
      this.props.setDirty,
    );
  };

  handleChangeAndResetClarificationField =
    handleChangeAndResetClarificationField(this.handleInput, this.props.clearFields);

  isUpdate = () => !matchPath(this.props.match.url, { path: paths.application.create });

  isWithExtraGuides = () => languagesWithExtraGuides.includes(i18n.language());

  render() {
    const personalDetailsPath = generatePath(this.props.match.path, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.personalDetails,
    });
    const travelDocumentsPath = generatePath(this.props.match.path, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.travelDocuments,
    });
    const occupationAndResidencePath = generatePath(this.props.match.path, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.occupationAndResidence,
    });
    const journeyDetailsPath = generatePath(this.props.match.path, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.journeyDetails,
    });
    const contactsAndAccommodationPath = generatePath(this.props.match.path, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.contactsAndAccommodation,
    });
    const applicationDocumentsPath = generatePath(this.props.match.path, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.applicationDocuments,
    });
    const finalizePath = generatePath(this.props.match.path, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.finalize,
    });
    const finalizePathCreate = generatePath(paths.application.create, {
      tab: paths.application.tabs.finalize,
    });
    const finalizePathUpdate = generatePath(paths.application.update, {
      applicationId: ':applicationId',
      tab: paths.application.tabs.finalize,
    });

    const formTabsList = [
      'personalDetails',
      'travelDocuments',
      'occupationAndResidence',
      'journeyDetails',
      'contactsAndAccommodation',
      'applicationDocuments',
      'finalize',
    ];

    const tabs = R.map(tabName => ({
      name: tabName,
      path: generatePath(this.props.match.path, {
        applicationId: this.props.match.params.applicationId,
        tab: paths.application.tabs[tabName],
      }),
      displayName: i18n.t(`application.tabs.${tabName}`),
    }), formTabsList);

    const validateTabFor = R.curryN(2, this.props.validateTab)(this.props.form);
    const captchaNotAvailable = !window.mtcaptcha;
    return (
      <Loading requestNames={[actions.applicationFetched().type]}>
        {
          !this.props.applicationFetchWasSuccessful ?
            <InlineNotification type="warning" disableClose>
              {i18n.t('applicationErrors.applicationFetchFailed')}
            </InlineNotification> :
            <>
              <div className="application__header">
                <div className="container">
                  <h1>{i18n.t('application.title')}</h1>
                  <NavbarForTabs
                    navClassName="application__navbar"
                    tabs={tabs}
                    tabValidationData={this.props.formTabs}
                  />
                </div>
              </div>
              <Form className="container" id="main" tabIndex="-1">
                { captchaNotAvailable &&
                  <Route path={paths.application.create}>
                    <InlineNotification type="warning" dataCy="inline-warning-captcha-not-available" disableClose>
                      {i18n.t('applicationErrors.captchaNotAvailable')}
                    </InlineNotification>
                  </Route>}
                <Switch>
                  <Route path={personalDetailsPath}>
                    <DocumentTitle>{i18n.t('application.tabs.personalDetails')}</DocumentTitle>
                    <FormTab
                      key="personalDetails"
                      proceedToPath={paths.application.tabs.travelDocuments}
                      proceedToName={i18n.t('application.tabs.travelDocuments')}
                      validateTab={() => validateTabFor('personalDetails')}
                    >
                      { this.props.form.travelAgencyName.value &&
                        <>
                          <TwoColumnRow oneColumn>
                            <LabeledValue
                              labelText={`${i18n.t('common:applicationForm.travelAgencyName')}`}
                              dataCy="labeled-travelAgencyName"
                              value={this.props.form.travelAgencyName.value}
                              withBottomBorder
                            />
                          </TwoColumnRow>
                          <Divider fullWidth withTopMargin withBottomMargin />
                        </>}
                      <TabSubtitle id="applicationForm-personalDetailsSubtitle" type="h3">{i18n.t('common:applicationForm.personalDetailsSubtitle')}</TabSubtitle>

                      <PersonalDetails
                        handleInput={this.handleInput}
                        handleChangeAndResetClarificationField={
                          this.handleChangeAndResetClarificationField
                        }
                        updateListField={this.updateListField}
                        clearFields={this.props.clearFields}
                        surname={this.props.form.surname}
                        surnameAtBirth={this.props.form.surnameAtBirth}
                        firstName={this.props.form.firstName}
                        formerSurnames={this.props.form.formerSurnames}
                        formerSurnamesInput={this.props.form.formerSurnamesInput}
                        dateOfBirth={this.props.form.dateOfBirth}
                        placeOfBirth={this.props.form.placeOfBirth}
                        countryOfBirth={this.props.form.countryOfBirth}
                        currentNationality={this.props.form.currentNationality}
                        nationalityAtBirth={this.props.form.nationalityAtBirth}
                        otherNationalities={this.props.form.otherNationalities}
                        sex={this.props.form.sex}
                        maritalStatus={this.props.form.maritalStatus}
                        maritalStatusSpecify={this.props.form.maritalStatusSpecify}
                        nationalIdentityNumber={this.props.form.nationalIdentityNumber}
                        guardianSurname={this.props.form.guardianSurname}
                        guardianFirstName={this.props.form.guardianFirstName}
                        guardianNationality={this.props.form.guardianNationality}
                        guardianAddress={this.props.form.guardianAddress}
                        guardianAddressIsDifferentThanApplicants={
                          this.props.form.guardianAddressIsDifferentThanApplicants
                        }
                        guardianEmail={this.props.form.guardianEmail}
                        guardianPhonenumber={this.props.form.guardianPhonenumber}
                        secondaryGuardianSurname={this.props.form.secondaryGuardianSurname}
                        secondaryGuardianFirstName={this.props.form.secondaryGuardianFirstName}
                        secondaryGuardianNationality={this.props.form.secondaryGuardianNationality}
                        secondaryGuardianAddress={this.props.form.secondaryGuardianAddress}
                        secondaryGuardianAddressIsDifferentThanApplicants={
                          this.props.form.secondaryGuardianAddressIsDifferentThanApplicants
                        }
                        secondaryGuardianEmail={this.props.form.secondaryGuardianEmail}
                        secondaryGuardianPhonenumber={this.props.form.secondaryGuardianPhonenumber}
                        emailRequired
                        codesetSelectContainer={CodesetSelectContainer}
                        codesetListFieldContainer={CodesetListFieldContainer}
                        oneColumn
                        showEmailReminder
                      />

                      <Divider fullWidth withBottomMargin />

                      <EuCitizen
                        clearFields={this.props.clearFields}
                        euFirstname={this.props.form.euFirstname}
                        euSurname={this.props.form.euSurname}
                        euDateOfBirth={this.props.form.euDateOfBirth}
                        euPassportNumber={this.props.form.euPassportNumber}
                        euFamilyRelationshipCode={this.props.form.euFamilyRelationshipCode}
                        euFamilyRelationshipSpecify={this.props.form.euFamilyRelationshipSpecify}
                        euNationality={this.props.form.euNationality}
                        eucitizenFamily={this.props.form.eucitizenFamily}
                        handleChange={this.handleInput}
                        handleChangeAndResetClarificationField={
                          this.handleChangeAndResetClarificationField
                        }
                        validateTopFieldsInContactsSection={
                          this.props.validateInvitationAndTravelCostTopFields
                        }
                        codesetSelectContainer={CodesetSelectContainer}
                        oneColumn
                        useLongDates
                        uppercase
                      />
                    </FormTab>
                  </Route>
                  <Route exact path={travelDocumentsPath}>
                    <DocumentTitle>{i18n.t('application.tabs.travelDocuments')}</DocumentTitle>
                    <FormTab
                      key="travelDocuments"
                      backToPath={paths.application.tabs.personalDetails}
                      backToName={i18n.t('application.tabs.personalDetails')}
                      proceedToPath={paths.application.tabs.occupationAndResidence}
                      proceedToName={i18n.t('application.tabs.occupationAndResidence')}
                      validateTab={() => validateTabFor('travelDocuments')}
                    >
                      <TabSubtitle id="applicationForm-travelDocumentsSubtitle" type="h3">{i18n.t('common:applicationForm.travelDocumentsSubtitle')}</TabSubtitle>
                      <TravelDocuments
                        handleInput={this.handleInput}
                        travelDocumentType={this.props.form.travelDocumentType}
                        travelDocumentTypeSpecify={this.props.form.travelDocumentTypeSpecify}
                        travelDocumentNumber={this.props.form.travelDocumentNumber}
                        travelDocumentIssueDate={this.props.form.travelDocumentIssueDate}
                        travelDocumentValidUntil={this.props.form.travelDocumentValidUntil}
                        travelDocumentCountryIssued={this.props.form.travelDocumentCountryIssued}
                        isUpdate={this.isUpdate()}
                        codesetSelectContainer={CodesetSelectContainer}
                        oneColumn
                      />
                    </FormTab>
                  </Route>
                  <Route exact path={occupationAndResidencePath}>
                    <DocumentTitle>{i18n.t('application.tabs.occupationAndResidence')}</DocumentTitle>
                    <FormTab
                      key="occupationAndResidence"
                      backToPath={paths.application.tabs.travelDocuments}
                      backToName={i18n.t('application.tabs.travelDocuments')}
                      proceedToPath={paths.application.tabs.journeyDetails}
                      proceedToName={i18n.t('application.tabs.journeyDetails')}
                      validateTab={() => validateTabFor('occupationAndResidence')}
                    >
                      <TabSubtitle id="applicationForm-occupationAndResidenceSubtitle" type="h3">{i18n.t('common:applicationForm.occupationAndResidenceSubtitle')}</TabSubtitle>

                      <OccupationAndResidence
                        handleInput={this.handleInput}
                        handleChangeAndResetClarificationField={
                          this.handleChangeAndResetClarificationField
                        }
                        updateListField={this.updateListField}
                        clearFields={this.props.clearFields}
                        address={this.props.form.address}
                        secondaryAddress={this.props.form.secondaryAddress}
                        email={this.props.form.email}
                        phonenumbers={this.props.form.phonenumbers}
                        phonenumbersInput={this.props.form.phonenumbersInput}
                        residenceInOtherCountry={this.props.form.residenceInOtherCountry}
                        residencePermitNumber={this.props.form.residencePermitNumber}
                        residencePermitValidUntil={this.props.form.residencePermitValidUntil}
                        occupationCode={this.props.form.occupationCode}
                        occupationOther={this.props.form.occupationOther}
                        employerName={this.props.form.employerName}
                        schoolName={this.props.form.schoolName}
                        employerAddress={this.props.form.employerAddress}
                        employerPhonenumber={this.props.form.employerPhonenumber}
                        employerEmail={this.props.form.employerEmail}
                        eucitizenFamily={this.props.form.eucitizenFamily}
                        codesetSelectContainer={CodesetSelectContainer}
                        oneColumn
                        emailRequired
                      />
                    </FormTab>
                  </Route>
                  <Route exact path={journeyDetailsPath}>
                    <DocumentTitle>{i18n.t('application.tabs.journeyDetails')}</DocumentTitle>
                    <FormTab
                      key="journeyDetails"
                      backToPath={paths.application.tabs.occupationAndResidence}
                      backToName={i18n.t('application.tabs.occupationAndResidence')}
                      proceedToPath={paths.application.tabs.contactsAndAccommodation}
                      proceedToName={i18n.t('application.tabs.contactsAndAccommodation')}
                      validateTab={() => validateTabFor('journeyDetails')}
                    >
                      <TabSubtitle id="applicationForm-journeyDetailsSubtitle" type="h3">{i18n.t('common:applicationForm.journeyDetailsSubtitle')}</TabSubtitle>
                      <JourneyDetails
                        handleChange={this.handleInput}
                        clearFields={this.props.clearFields}
                        destinationStates={this.props.form.destinationStates}
                        borderOfFirstEntryCountry={this.props.form.borderOfFirstEntryCountry}
                        numberOfEntriesRequested={this.props.form.numberOfEntriesRequested}
                        dateOfArrival={this.props.form.dateOfArrival}
                        dateOfDeparture={this.props.form.dateOfDeparture}
                        fingerprintsAlreadyStored={this.props.form.fingerprintsAlreadyStored}
                        fingerprintsStoredAt={this.props.form.fingerprintsStoredAt}
                        fingerprintsVisaNumber={this.props.form.fingerprintsVisaNumber}
                        mainPurposeOfJourney={this.props.form.mainPurposeOfJourney}
                        otherPurposesOfJourney={this.props.form.otherPurposesOfJourney}
                        journeyPurposeDetails={this.props.form.journeyPurposeDetails}
                        journeyPurposeOther={this.props.form.journeyPurposeOther}
                        oneDayTripWithoutAccommodation={
                          this.props.form.oneDayTripWithoutAccommodation
                        }
                        entryPermitForFinalDestination={
                          this.props.form.entryPermitForFinalDestination
                        }
                        entryPermit={this.props.form.entryPermit}
                        isAdsCapable={false}
                        codesetSelectContainer={CodesetSelectContainer}
                        codesetListFieldContainer={CodesetListFieldContainer}
                        oneColumn
                      />
                    </FormTab>
                  </Route>
                  <Route exact path={contactsAndAccommodationPath}>
                    <DocumentTitle>{i18n.t('application.tabs.contactsAndAccommodation')}</DocumentTitle>
                    <FormTab
                      key="contactsAndAccommodation"
                      backToPath={paths.application.tabs.journeyDetails}
                      backToName={i18n.t('application.tabs.journeyDetails')}
                      proceedToPath={paths.application.tabs.applicationDocuments}
                      proceedToName={i18n.t('application.tabs.applicationDocuments')}
                      validateTab={() => validateTabFor('contactsAndAccommodation')}
                    >
                      <ContactsAndAccomodation
                        handleInput={this.handleInput}
                        handleArrayChange={this.props.formArrayUpdate}
                        clearFields={this.props.clearFields}
                        validateFields={this.props.validateFields}
                        initField={this.props.formFieldSet}
                        eucitizenFamily={this.props.form.eucitizenFamily}
                        contactOrAccommodation={this.props.form.contactOrAccommodation}
                        invitingOrganization={this.props.form.invitingOrganization}
                        invitingOrganizationContact={this.props.form.invitingOrganizationContact}
                        invitingPersons={this.props.form.invitingPersons}
                        accommodations={this.props.form.accommodations}
                        oneDayTripWithoutAccommodation={
                          this.props.form.oneDayTripWithoutAccommodation
                        }
                        travelCostsApplicantInUse={this.props.form.travelCostsApplicantInUse}
                        travelCostsApplicant={this.props.form.travelCostsApplicant}
                        travelCostApplicantOther={this.props.form.travelCostApplicantOther}
                        travelCostsSponsorInUse={this.props.form.travelCostsSponsorInUse}
                        sponsorHost={this.props.form.sponsorHost}
                        sponsorHostSpecify={this.props.form.sponsorHostSpecify}
                        sponsorOther={this.props.form.sponsorOther}
                        sponsorOtherSpecify={this.props.form.sponsorOtherSpecify}
                        travelCostsSponsor={this.props.form.travelCostsSponsor}
                        travelCostSponsorOther={this.props.form.travelCostSponsorOther}
                        filledBySurname={this.props.form.filledBySurname}
                        filledByFirstName={this.props.form.filledByFirstName}
                        filledByAddress={this.props.form.filledByAddress}
                        filledByEmail={this.props.form.filledByEmail}
                        filledByPhonenumber={this.props.form.filledByPhonenumber}
                        filledByOtherThanApplicant={this.props.form.filledByOtherThanApplicant}
                        codesetSelectContainer={CodesetSelectContainer}
                        oneColumn
                        showFaxnumber={false}
                      />
                    </FormTab>
                  </Route>
                  <Route exact path={applicationDocumentsPath}>
                    <DocumentTitle>{i18n.t('application.tabs.applicationDocuments')}</DocumentTitle>
                    <FormTab
                      key="applicationDocuments"
                      backToPath={paths.application.tabs.contactsAndAccommodation}
                      backToName={i18n.t('application.tabs.contactsAndAccommodation')}
                      proceedToPath={paths.application.tabs.finalize}
                      proceedToName={i18n.t('application.tabs.finalize')}
                      validateTab={() => validateTabFor('applicationDocuments')}
                    >
                      <ApplicationDocuments
                        handleSetEspLocation={this.handleSetEspLocation}
                        handleSetChecklist={this.handleSetChecklist}
                        formArrayUpdate={this.props.formArrayUpdate}
                        espLocation={this.props.form.espLocation}
                        checklistName={this.props.form.checklistName}
                        checklists={this.props.checklists}
                        applicationChecklistItems={this.props.applicationChecklistItems}
                        checklistCountrySpecificInstructions={
                          this.props.checklistCountrySpecificInstructions
                        }
                        checklistItemAttachments={this.props.checklistItemAttachments}
                        dateOfBirth={this.props.form.dateOfBirth}
                      />
                    </FormTab>
                  </Route>
                  <Route exact path={finalizePath}>
                    <DocumentTitle>{i18n.t('application.tabs.finalize')}</DocumentTitle>
                    <FormTab
                      key="finalize"
                      backToPath={paths.application.tabs.applicationDocuments}
                      backToName={i18n.t('application.tabs.applicationDocuments')}
                    >
                      <TabSubtitle type="h3">{i18n.t('application.tabs.finalize')}</TabSubtitle>
                      <Finalize
                        validationErrorNumber={this.props.validationErrorNumber}
                        validateAllTabs={() =>
                          this.props.validateAllTabs(this.props.form, rules(true))}
                        form={this.props.form}
                        applicationChecklistItems={this.props.applicationChecklistItems}
                      />
                    </FormTab>
                  </Route>
                  <Redirect to={generatePath(this.props.match.path, {
                    applicationId: this.props.match.params.applicationId,
                    tab: paths.application.tabs.personalDetails,
                  })}
                  />
                </Switch>

                <div id="mtcaptcha-2" className="mtcaptcha" />

                <Switch>
                  <Route path={paths.application.create}>
                    <SaveButton onClick={this.handleCreateDraft} disabled={captchaNotAvailable} />
                  </Route>
                  <Route path={paths.application.update}>
                    <SaveButton onClick={this.handleUpdateDraft} />
                  </Route>
                </Switch>

                <Switch>
                  <Route exact path={finalizePathCreate}>
                    <SendToDoneButton
                      disabled={this.props.validationErrorNumber > 0 || captchaNotAvailable}
                      onClick={this.handleCreateDraftToDone}
                    />
                  </Route>
                  <Route exact path={finalizePathUpdate}>
                    <SendToDoneButton
                      disabled={this.props.validationErrorNumber > 0}
                      onClick={this.handleUpdateDraftToDone}
                    />
                  </Route>
                </Switch>
              </Form>

              <Switch>
                <Route path={personalDetailsPath}>
                  <Guide openModalAtStart={this.openModalAtStart} describedBy="guide-personalDetailsSubtitle">
                    <PersonalDetailsGuide>
                      {this.isWithExtraGuides() ?
                        <EuFamilyRelationshipGuideWithExtras /> :
                        <EuFamilyRelationshipGuide />}
                    </PersonalDetailsGuide>
                  </Guide>
                </Route>
                <Route path={travelDocumentsPath}>
                  <Guide openModalAtStart={this.openModalAtStart} describedBy="guide-travelDocumentsSubtitle"><TravelDocumentsGuide /></Guide>
                </Route>
                <Route path={occupationAndResidencePath}>
                  <Guide openModalAtStart={this.openModalAtStart} describedBy="guide-occupationAndResidenceSubtitle">
                    <OccupationAndResidenceGuide>
                      { this.isWithExtraGuides() ?
                        <ResidencePermitGuideWithExtras /> :
                        <ResidencePermitGuide />}
                    </OccupationAndResidenceGuide>
                  </Guide>
                </Route>
                <Route path={journeyDetailsPath}>
                  <Guide openModalAtStart={this.openModalAtStart} describedBy="guide-journeyDetailsSubtitle">
                    <JourneyDetailsGuide>
                      { this.isWithExtraGuides() ?
                        <FingerPrintsAndDestinationPermitGuideWithExtras /> :
                        <FingerPrintsAndDestinationPermitGuide />}
                    </JourneyDetailsGuide>
                  </Guide>
                </Route>
                <Route path={contactsAndAccommodationPath}>
                  <Guide openModalAtStart={this.openModalAtStart} describedBy="guide-invitations-title">
                    <ContactsAndAccommodationGuide />
                  </Guide>
                </Route>
                <Route path={applicationDocumentsPath}>
                  <Guide openModalAtStart={this.openModalAtStart} describedBy="guide-applicationDocuments">
                    <ApplicationDocumentsGuide />
                  </Guide>
                </Route>
                <Route path={finalizePath}>
                  <Guide openModalAtStart={this.openModalAtStart} describedBy="guide-finalize"><FinalizeGuide /></Guide>
                </Route>
              </Switch>
            </>
        }
      </Loading>
    );
  }
}

ApplicationForm.propTypes = {
  match: matchShape.isRequired,
  saveDraftApplication: PropTypes.func.isRequired,
  saveDraftApplicationToDone: PropTypes.func.isRequired,
  updateDraftApplication: PropTypes.func.isRequired,
  updateDraftApplicationToDone: PropTypes.func.isRequired,
  fetchApplication: PropTypes.func.isRequired,
  setFieldAndValidate: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  validateInvitationAndTravelCostTopFields: PropTypes.func.isRequired,
  formCleared: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  validateTab: PropTypes.func.isRequired,
  validateAllTabs: PropTypes.func.isRequired,
  pathId: PropTypes.string,
  formFieldSet: PropTypes.func.isRequired,
  formArrayUpdate: PropTypes.func.isRequired,
  form: ovafApplicationFormShape.isRequired,
  formTabs: ovafApplicationFormTabsShape.isRequired,
  applicationFetchWasSuccessful: PropTypes.bool.isRequired,
  validationErrorNumber: PropTypes.number,
  setDirty: PropTypes.func.isRequired,
  setEspLocation: PropTypes.func.isRequired,
  setChecklist: PropTypes.func.isRequired,
  checklists: PropTypes.arrayOf(checklistShape),
  fetchChecklistItemTypes: PropTypes.func.isRequired,
  applicationChecklistItems:
    PropTypes.arrayOf(PropTypes.exact(applicationChecklistItemFormWithMetadata)),
  checklistCountrySpecificInstructions: PropTypes.string,
  checklistItemAttachments: PropTypes.arrayOf(checklistItemAttachmentShape),
};

ApplicationForm.defaultProps = {
  pathId: null,
  validationErrorNumber: 0,
  checklists: [],
  applicationChecklistItems: [],
  checklistItemAttachments: [],
  checklistCountrySpecificInstructions: null,
};

export default ApplicationForm;
