import * as R from 'ramda';
import moment from 'moment';

import { isNilOrEmpty, notNilOrEmpty, flattenObject } from '../../utils/commonUtils';
import i18n from '../i18n';
import {
  localTimeFormat,
  localTimeInMinutesFormat,
  dateFormat,
  jsonDateStringToLocalString,
  dateIsInRange,
} from '../datetime';
import { hideJsonPseudo } from '../pseudoDateUtils';
import { aurnDateFormat, aurnPatterns } from '../../utils/aurnUtils';

// Regex validation patterns

const validationPatterns = {
  stringIncludesNonWhitespace: '[\\s]*[\\S]+[\\s]*',
  generalAllowedCharacters: '^[ .,-/=\'+;:!?()@_~A-Za-zÀÁÂÃÄÅÆÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæèéêëìíîïñòóôõöøùúûüýß0-9]*$',
  extendedAllowedCharacters: '^[\\s.,-/=\'+;:!?()@_~A-Za-zÀÁÂÃÄÅÆÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜŠÝŽßàáâãäåæèéêëìíîïñòóôõöøùúûüšýžß\\p{Script=Cyrillic}0-9]*$',
  codesetTextAllowedCharacters: '^[\\s\\n\\r.–,-/=’`\'"+;:!?()\\[\\]@_€§~A-Za-zÀÁÂÃÄÅÆÇĐÈÉÊËÌÍÎÏÑÒÓÔÕÖỜØÙÚÛÜŠÝŽßàáâãäåæçđèéêëìíîïñòóôõöồøùúûüšýžß\\p{Script=Cyrillic}\\p{Script=Arabic}(و،)\\p{Script=Devanagari}\\p{Script=Greek}\\p{Script=Han}（）、\\p{Script=Latin}\\p{Script=Thai}0-9]*$',
  allowedNameCharacters: '^[ \\-A-Za-zÀÁÂÃÄÅÆÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæèéêëìíîïñòóôõöøùúûüýß]*$',
  noGeneralAllowedLowercaseLetters: '^[^a-zàáâãäåæèéêëìíîïñòóôõöøùúûüýß]*$',
  applicationNumber: '^FIN(VISA)?\\d+$',
  cvisApplicationNumber: '^[A-Z]{3}.{1,30}$',
  cvisStickerNumber: '^[A-Z]{1,3}.{1,30}$',
  cvisTravelDocumentNumberV2: '^[A-Za-z0-9]{1,50}$',
  cvisTravelDocumentNumberV3: '^[A-Za-z0-9]{1,27}$',
  email: '^(([^@])+@([^@])+)$',
  phoneNumber: '^((\\+){0,1}([0-9])+)$',
  digits: '^\\d+$',
  decimal: '^\\d{1,10}((\\.|\\,)\\d{1,3}){0,1}$',
  integer: '^(\\+|\\-){0,1}\\d+$',
  currency: '^[A-Z]{3}$',
  aurn: aurnPatterns.aurn,
  aurnV2: aurnPatterns.aurnV2,
  vurn: '^([A-Z]{10})$',
  transliteratedName: '^[A-Za-zÀ-ÖØ-öø-ž\'(),/:?+ -]+$',
  visaStickerNumber: '^\\d{1,15}$|^FIN\\d{1,15}$',
  visaFullStickerNumber: '^FIN\\d{1,15}$',
  travelDocumentNumber: '^[A-Z0-9]{4,9}$',
  organizationCode: '^[A-Za-z0-9]{3}$',
  vacId: '^[A-Z]{4}$',
  vismailIntegrationMessageId: '^[A-Z]{3}[0-9A-Z/_\\-]{1,47}$',
  codesetCode: '^[A-Za-z0-9\\(\\)\\-/_]+$',
  ovafPassword: '^(?=.*[a-z])[a-zA-Z0-9\\^$*.\\[\\]{}\\(\\)?\\-"!@#%&\\/,><\\’:;|_~`]+$',
  diaryNumber: '([0-9]{1,7})/([0-9]{3})/([0-9]{4})',
  businessId: '^[0-9]{7}\\-[0-9]$',
  cognitoUsername: '^([^,;\\s])+$', // [\p{L}\p{M}\p{S}\p{N}\p{P}]+ also some undocumented constraints
};

const validationFormats = {
  currency: 'XXX',
};

// Private utils.

const trimIfString = R.when(R.is(String), R.trim);

// Validators

export const required = (value) => {
  return (R.compose(isNilOrEmpty, trimIfString)(value) ?
    i18n.t('common:validationMessages.valueMissing') :
    null);
};

export const isTrue = (value) => {
  return value === true ? null : i18n.t('common:validationMessages.valueMissing');
};

export const maxLength = max => value =>
  (R.anyPass([R.isNil, val => R.lte(val.toString().length, max)])(value) ?
    null :
    i18n.t('common:validationMessages.tooLong', { max }));

export const minLength = min => value =>
  (R.anyPass([R.isNil, val => R.gte(val.toString().length, min)])(value) ?
    null :
    i18n.t('common:validationMessages.tooShort', { min }));

export const exactLength = num => value =>
  (R.anyPass([R.isNil, val => R.equals(val.toString().length, num)])(value) ?
    null :
    i18n.t('common:validationMessages.exactLength', {
      num,
      length: value.length,
    }));

export const arrayMaxLength = max => value =>
  (!value || max >= R.length(value) ?
    null :
    i18n.t('common:validationMessages.tooMany'));

export const dateFor = (
  format,
  displayFormat,
) => value =>
  (!value || moment(value, format, true).isValid() ?
    null :
    i18n.t('common:validationMessages.patternMismatch', {
      format: `(${displayFormat || format})`,
    }));

export const dateForWithoutFormat = format => value =>
  (!value || moment(value, format, true).isValid() ?
    null :
    i18n.t('common:validationMessages.patternMismatch'));

export const date = (
  displayFormat = i18n.t('common:dateFormats.displayFormat'),
) => dateFor(dateFormat, displayFormat);

export const pseudoDate = (
  format = i18n.t('common:dateFormats.displayFormat'),
) => value =>
  (!value || jsonDateStringToLocalString(value, format) !== value ?
    null :
    i18n.t('common:validationMessages.patternMismatch', {
      format: `(${format})`,
    }));

export const localTime = (
  format = i18n.t('common:timeFormats.inputFormatDescription'),
) => value =>
  (!value || moment(value, localTimeFormat, true).isValid() ?
    null :
    i18n.t('common:validationMessages.patternMismatch', {
      format: `(${format})`,
    }));

export const localTimeInMinutes = (
  format = i18n.t('common:timeFormats.inputFormatMinutesDescription'),
) => value =>
  (!value || (moment(value, localTimeInMinutesFormat, true).isValid() && R.not(R.equals(value, '24:00'))) ?
    null :
    i18n.t('common:validationMessages.patternMismatch', {
      format: `(${format})`,
    }));

export const fileType = type => value =>
  (R.anyPass([R.isNil, val => R.includes(val.type, type)])(value) ?
    null :
    i18n.t('common:validationMessages.invalidFileType'));

export const maxFileSizeInKB = max => value =>
  (R.anyPass([R.isNil, val => R.lte(val.size, max * 1024)])(value) ?
    null :
    i18n.t('common:validationMessages.fileSizeTooLarge', { maxMB: max / 1000 }));

export const maxTotalFileSizeInKB = max => value =>
  (R.anyPass([
    isNilOrEmpty,
    values => R.compose(
      R.lte(R.__, max * 1024),
      R.sum,
      R.pluck('size'),
    )(values),
  ])(value) ?
    null :
    i18n.t('common:validationMessages.totalFileSizeTooLarge', { maxMB: max / 1000 }));

export const oneOf = list => value => (R.includes(value, list) ?
  null :
  i18n.t('common:validationMessages.valueMissing'));

const patternMatchFor = R.curry((errorMessage, pattern) => value =>
  (R.anyPass([R.isNil, R.test(new RegExp(pattern))])(value) ?
    null :
    errorMessage));

const unicodePatternMatchFor = R.curry((errorMessage, pattern) => value =>
  (R.anyPass([R.isNil, R.test(new RegExp(pattern, 'u'))])(value) ?
    null :
    errorMessage));

const patternMatch = (
  pattern,
  format,
) => patternMatchFor(i18n.t('common:validationMessages.patternMismatch', { format }))(pattern);

const allowedCharacters = patternMatchFor(i18n.t('common:validationMessages.illegalCharacter'));

export const generalAllowedCharacters =
  allowedCharacters(validationPatterns.generalAllowedCharacters);

export const extendedAllowedCharacters =
  unicodePatternMatchFor(i18n.t('common:validationMessages.illegalCharacter'), validationPatterns.extendedAllowedCharacters);

export const codesetTextAllowedCharacters =
  unicodePatternMatchFor(i18n.t('common:validationMessages.illegalCharacter'), validationPatterns.codesetTextAllowedCharacters);

export const notOnlyWhitespace =
  patternMatchFor(i18n.t('common:validationMessages.onlyWhitespace'), validationPatterns.stringIncludesNonWhitespace);

export const noLowercaseLetters =
  patternMatchFor(i18n.t('common:validationMessages.lowercaseLetter'), validationPatterns.noGeneralAllowedLowercaseLetters);

export const allowedNameCharacters =
  allowedCharacters(validationPatterns.allowedNameCharacters);

export const applicationNumber = () => value =>
  patternMatch(validationPatterns.applicationNumber)(value);

export const cvisApplicationNumber = () => value =>
  patternMatch(validationPatterns.cvisApplicationNumber)(value);

export const cvisStickerNumber = () => value =>
  patternMatch(validationPatterns.cvisStickerNumber)(value);

export const cvisTravelDocumentNumberV2 = () => value =>
  patternMatch(validationPatterns.cvisTravelDocumentNumberV2)(value);

export const cvisTravelDocumentNumberV3 = () => value =>
  patternMatch(validationPatterns.cvisTravelDocumentNumberV3)(value);

export const cognitoUsername = allowedCharacters(validationPatterns.cognitoUsername);
export const emailFormat = () => value => patternMatchFor(
  i18n.t('common:validationMessages.patternMismatch', {
    format: `(${i18n.t('common:miscellaneousFormats.emailFormatDescription')})`,
  }),
  validationPatterns.email,
)(value);

export const ovafPasswordFormat = () => value =>
  minLength(14)(value) ||
  maxLength(99)(value) ||
  allowedCharacters(validationPatterns.ovafPassword)(value);

const phoneFormat = () => value => patternMatchFor(
  i18n.t('common:validationMessages.patternMismatch', {
    format: `(${i18n.t('common:miscellaneousFormats.phonenumberFormatDescription')})`,
  }),
  validationPatterns.phoneNumber,
)(value);

export const phoneNumber = () => value =>
  phoneFormat()(value) ||
  minLength(6)(value) ||
  maxLength(50)(value);

export const currency = patternMatch(
  validationPatterns.currency,
  `(${validationFormats.currency})`,
);

export const decimal = patternMatch(
  validationPatterns.decimal,
  `(${i18n.t('common:miscellaneousFormats.decimalFormatDescription')})`,
);

export const integer = value => patternMatch(validationPatterns.integer)(value);

export const aurn = () => value => patternMatch(validationPatterns.aurn)(value);

export const aurnV2 = () => (value) => {
  if (value || R.isEmpty((value))) {
    const matches = value.match(validationPatterns.aurnV2);
    return (matches) ?
      dateForWithoutFormat(aurnDateFormat)(matches[2]) :
      i18n.t('common:validationMessages.patternMismatch');
  }
  return null;
};

export const aurnVacMatch = vacId => value =>
  (R.anyPass([R.isNil, R.test(new RegExp(`^${vacId}.+`))])(value) ?
    null :
    i18n.t('common:validationMessages.aurnVacMismatch'));

const checksumMatch = (vurn) => {
  const parts = R.splitAt(-1, vurn);
  const checksum = R.compose(
    R.sum,
    R.map(c => c.charCodeAt(0) - 65),
  )(parts[0]);
  const checksumCharCode = 65 + R.modulo(checksum, 26);
  return checksumCharCode === parts[1].charCodeAt(0) ?
    null :
    i18n.t('common:validationMessages.vurnChecksumMismatch');
};

export const vurn = () => (value) => {
  if (isNilOrEmpty(value)) {
    return null;
  }
  return patternMatch(validationPatterns.vurn)(value) ||
    checksumMatch(value);
};

export const visaStickerNumber = value => patternMatch(validationPatterns.visaStickerNumber)(value);

export const visaFullStickerNumber = value =>
  patternMatch(validationPatterns.visaFullStickerNumber)(value);

export const travelDocumentNumber = value =>
  patternMatch(validationPatterns.travelDocumentNumber)(value);

export const organizationCode = value => patternMatch(validationPatterns.organizationCode)(value);

export const vacId = value => patternMatchFor(
  i18n.t('common:validationMessages.invalidVacId'),
  validationPatterns.vacId,
)(value);

export const vismailReferenceNumber = () => value =>
  patternMatch(validationPatterns.vismailIntegrationMessageId)(value);

export const codesetCode = () => value =>
  patternMatch(validationPatterns.codesetCode)(value);

export const diaryNumber = value => patternMatch(validationPatterns.diaryNumber)(value);

export const businessId = value => patternMatch(validationPatterns.businessId)(value);

export const transliteratedNames = (value) => {
  const isValid = R.compose(
    isNilOrEmpty,
    R.reject(isNilOrEmpty),
    R.map(R.compose(
      patternMatch(validationPatterns.transliteratedName),
      R.trim,
    )),
    R.split(','),
  );
  return R.anyPass([R.isNil, isValid])(value) ? null : i18n.t('common:validationMessages.patternMismatch');
};

export const nameCharacters = (value) => {
  if (!value) {
    return null;
  }

  const illegalCharacters = '0123456789<>|!"#¤%&/()=+*~^@£$[]{}\\§½';

  if (illegalCharacters.split('').some(illegalChar => R.includes(illegalChar, value))) {
    return i18n.t('common:validationMessages.illegalCharacter');
  }

  return null;
};

export const aurnTravelDocumentOrApplicationNumber = () => (value) => {
  if (isNilOrEmpty(value)) {
    return null;
  }

  return R.any(R.isNil)([
    aurn()(value),
    applicationNumber()(value),
    travelDocumentNumber(value),
  ]) ? null : i18n.t('common:validationMessages.aurnTravelDocumentOrApplicationNumberInvalid');
};

export const numericRange = (min, max) => value =>
  ((R.isNil(value) ||
    R.is(Boolean, value) ||
    (R.is(String, value) && R.isEmpty(R.trim(value))) ||
    !Number.isInteger(Number(value)) || value < min || value > max) ?
    i18n.t('common:validationMessages.numberRangeMismatch', { min, max }) :
    null);

export const isBefore = limitDate => (value) => {
  const limit = moment(limitDate, dateFormat, true);
  const actual = moment(value, dateFormat, true);

  return limit.isValid() && actual.isValid() && limit.isSameOrBefore(actual) ? i18n.t('common:validationMessages.isBefore', {
    before: limit.format(i18n.t('common:dateFormats.displayFormat')),
  }) : null;
};

export const isBeforeOrEqualDate = limitDate => (value) => {
  const limit = moment(limitDate, dateFormat, true);
  const actual = moment(value, dateFormat, true);

  return limit.isValid() && actual.isValid() && limit.isBefore(actual) ? i18n.t('common:validationMessages.isBeforeOrEqual', {
    date: limit.format(i18n.t('common:dateFormats.displayFormat')),
  }) : null;
};

export const isAfter = limitDate => (value) => {
  const limit = moment(limitDate, dateFormat, true);
  const actual = moment(value, dateFormat, true);

  return limit.isValid() && actual.isValid() && limit.isSameOrAfter(actual) ? i18n.t('common:validationMessages.isAfter', {
    after: limit.format(i18n.t('common:dateFormats.displayFormat')),
  }) : null;
};

export const isAfterOrEqualDate = limitDate => (value) => {
  const limit = moment(limitDate, dateFormat, true);
  const actual = moment(value, dateFormat, true);

  return limit.isValid() && actual.isValid() && limit.isAfter(actual) ? i18n.t('common:validationMessages.isAfter', {
    after: limit.format(i18n.t('common:dateFormats.displayFormat')),
  }) : null;
};

export const pseudoIsAfterOrEqual = limitDate =>
  R.compose(isAfterOrEqualDate(limitDate), hideJsonPseudo);

export const pseudoIsBefore = limitDate => R.compose(isBefore(limitDate), hideJsonPseudo);

export const isValidPseudoDate = (format) => {
  const isValidFormat = pseudoDate(format);
  const isValidTimeRange = pseudoIsAfterOrEqual('1900-01-01');
  return value => isValidFormat(value) || isValidTimeRange(value);
};

export const isBetweenDates = (startDate, endDate) => (value) => {
  const start = moment(startDate, dateFormat, true);
  const end = moment(endDate, dateFormat, true);
  const actual = moment(value, dateFormat, true);

  return start.isValid() &&
    end.isValid() &&
    actual.isValid() &&
    !dateIsInRange(actual, start, end) ?
    i18n.t('common:validationMessages.isBetween', {
      between: `${start.format(i18n.t('common:dateFormats.displayFormat'))} - ${end.format(i18n.t('common:dateFormats.displayFormat'))}`,
    }) :
    null;
};

export const isPastOrPresent = (value) => {
  const actual = moment(value, dateFormat, true);
  return actual.isValid() && moment().isBefore(actual, 'day') ?
    i18n.t('common:validationMessages.isPastOrPresent') :
    null;
};

export const isUpToTenYearsInThePast = (value, referenceDate) => {
  const actual = moment(value, dateFormat, true);
  const reference = moment(referenceDate, dateFormat, true).subtract(10, 'years');
  return actual.isValid() && reference.isAfter(actual, 'day') ?
    i18n.t('common:validationMessages.isAfter', {
      after: reference.subtract(1, 'days').format(i18n.t('common:dateFormats.displayFormat')),
    }) : null;
};

export const isFutureOrPresent = (value) => {
  const actual = moment(value, dateFormat, true);
  return actual.isValid() && moment().isAfter(actual, 'day') ?
    i18n.t('common:validationMessages.futureOrPresent') :
    null;
};

export const isUpToSpecifiedYearsInTheFuture = (value, referenceDate, years) => {
  const actual = moment(value, dateFormat, true);
  const reference = moment(referenceDate, dateFormat, true).add(years, 'years');
  return actual.isValid() && reference.isBefore(actual, 'day') ?
    i18n.t('common:validationMessages.isBefore', {
      before: reference.add(1, 'days').format(i18n.t('common:dateFormats.displayFormat')),
    }) : null;
};

export const isUpToFifteenYearsDifference = (startDate, endDate) => {
  const start = moment(startDate, dateFormat, true);
  const end = moment(endDate, dateFormat, true);

  return start.isValid() && end.isValid() &&
    end.subtract(15, 'years').isAfter(start, 'day') ?
    i18n.t('common:validationMessages.fifteenYearsDifference') :
    null;
};

export const isFutureOrPresentUTCTime = (value) => {
  const actual = moment.utc(value, localTimeInMinutesFormat, true);
  return actual.isValid() && moment.utc().isAfter(actual, 'minute') ?
    i18n.t('common:validationMessages.futureTime') :
    null;
};

export const isBeforeOrEqualNumber = limit => (value) => {
  return R.isNil(limit) || R.isNil(value) || parseInt(value, 10) <= parseInt(limit, 10) ?
    null :
    i18n.t('common:validationMessages.endBeforeStart');
};

export const isAfterOrEqualNumber = limit => (value) => {
  return R.isNil(limit) || R.isNil(value) || parseInt(value, 10) >= parseInt(limit, 10) ?
    null :
    i18n.t('common:validationMessages.startBeforeEnd');
};

export const isUpToSpecifiedDaysInThePast = days => (value) => {
  const actual = moment(value, dateFormat, true);
  return actual.isValid() && moment().subtract(days, 'days').isSameOrBefore(actual, 'day') ?
    null :
    i18n.t('common:validationMessages.isUpToSpecifiedDaysInThePast', { days });
};

export const numberRangeCount = (countLimit, startValue) => (endValue) => {
  return R.isNil(startValue) || R.isNil(endValue) ||
    parseInt(endValue, 10) - parseInt(startValue, 10) < countLimit ?
    null :
    i18n.t('common:validationMessages.numberRangeCount', { countLimit });
};

export const onlyDigits = value =>
  (R.anyPass([isNilOrEmpty, R.test(new RegExp(validationPatterns.digits))])(value) ?
    null :
    i18n.t('common:validationMessages.onlyDigits'));

export const requireThisOrAnyOf =
  (fields, localizationKey) => (fieldValue, formValues) => {
    const isEmpty = R.compose(isNilOrEmpty, trimIfString);
    const otherValues = R.props(fields, formValues);
    return !isEmpty(fieldValue) || R.find(value => !isEmpty(value))(otherValues) ?
      null :
      i18n.t(localizationKey);
  };

const pickErrors = R.compose(
  R.defaultTo(null),
  R.head,
  R.reject(isNilOrEmpty),
);

export const listFieldValidator = validators =>
  R.compose(
    pickErrors,
    R.map(
      R.converge(
        R.compose(
          pickErrors,
          R.unapply(R.append([])),
        ),
        validators,
      ),
    ),
  );

export const uniqueListFieldValue = listPath => (fieldValue, formValues) => {
  if (!fieldValue) return null;

  const upperCaseListItems = R.compose(R.map(R.toUpper), R.path(listPath))(formValues);
  const trimmerUpperCaseFieldValue = R.compose(R.toUpper, R.trim)(fieldValue);

  if (R.includes(trimmerUpperCaseFieldValue, upperCaseListItems)) {
    return i18n.t('common:validationMessages.alreadyExists');
  }

  return null;
};

export const fingerPrintsScannedSuccessfully = (fieldValue, formValues) => {
  if (fieldValue && notNilOrEmpty(fieldValue.left) && notNilOrEmpty(fieldValue.right)) {
    return null;
  }

  // If the scan was not successful or is not done, the validation is still passed,
  // if the fingerprints were scanned earlier succesfully.
  if (formValues.fingerprintsAlreadyStored) {
    return null;
  }

  // If fingerprints are not applicable or not required, the validation is passed
  if (formValues.fingerprintsNotApplicable || formValues.fingerprintsNotRequired) {
    return null;
  }

  // In all the other cases, there are not valid fingerprints, so the validation fails
  return i18n.t('common:validationMessages.fingerPrintsMissing');
};

export const invitationFilled = (isRequired = true) => (fieldValue, formValues) => {
  const notFilled =
    isRequired &&
    R.not(formValues.oneDayTripWithoutAccommodation) &&
    R.not(formValues.eucitizenFamily) &&
    R.all(
      isNilOrEmpty,
      [
        formValues.invitingOrganization,
        formValues.invitingPersons,
        formValues.accommodations,
      ],
    );
  return notFilled ? i18n.t('common:validationMessages.valueMissing') : null;
};

export const oneDayTripWithoutAccommodationAllowed = () => (fieldValue, formValues) => {
  const otherInvitationsFilled =
    R.any(
      notNilOrEmpty,
      [
        formValues.invitingOrganization,
        formValues.invitingPersons,
        formValues.accommodations,
      ],
    );
  return formValues.oneDayTripWithoutAccommodation && otherInvitationsFilled ?
    i18n.t('common:validationMessages.oneDayTripWithoutAccommodationNotAllowed') : null;
};

export const minCountOfValidFingerprints = minCount => (fingerprints) => {
  if (!fingerprints) return null;
  return R.compose(
    R.ifElse(
      R.gte(R.__, minCount),
      R.always(null),
      R.always(i18n.t('common:validationMessages.minCountOfValidFingerprints', { count: minCount })),
    ),
    R.length,
    R.filter(R.equals('OK')),
    R.values,
    flattenObject(R.__, false),
  )(fingerprints);
};

export const validateWithMessage = (validator, localizationKey, interpolationObject = {}) => {
  return R.ifElse(
    validator,
    R.always(i18n.t(localizationKey, interpolationObject)),
    R.always(null),
  );
};
