import React from 'react';
import PropTypes from 'prop-types';

import Divider from 'visa-frontend-common/src/components/UI/Divider/Divider';
import { formAddressShape, formFieldShape, formFieldShapeFor } from 'visa-frontend-common/src/dataModel';

import FilledByPerson from 'visa-frontend-common/src/components/ApplicationForm/FilledByPerson';
import Invitations from 'visa-frontend-common/src/components/ApplicationForm/Invitations/Invitations';
import { invitationFormShape } from 'visa-frontend-common/src/components/ApplicationForm/Invitations/InvitationsDataModel';
import TravelCostsField from './TravelCostsField/TravelCostsField';

const ContactsAndAccomodation = (props) => {
  const CodesetSelectContainer = props.codesetSelectContainer;

  return (
    <>
      <Invitations
        handleChange={props.handleInput}
        handleArrayChange={props.handleArrayChange}
        clearFields={props.clearFields}
        validateFields={props.validateFields}
        initField={props.initField}
        eucitizenFamily={props.eucitizenFamily}
        contactOrAccommodation={props.contactOrAccommodation}
        invitingOrganization={props.invitingOrganization}
        invitingOrganizationContact={props.invitingOrganizationContact}
        invitingPersons={props.invitingPersons}
        accommodations={props.accommodations}
        oneDayTripWithoutAccommodation={props.oneDayTripWithoutAccommodation}
        autoFocus={false}
        codesetSelectContainer={CodesetSelectContainer}
        oneColumn={props.oneColumn}
        showFaxnumber={props.showFaxnumber}
        uppercase
      />
      <Divider fullWidth withBottomMargin />

      <TravelCostsField
        travelCostsApplicantInUse={props.travelCostsApplicantInUse}
        travelCostsApplicant={props.travelCostsApplicant}
        travelCostApplicantOther={props.travelCostApplicantOther}
        travelCostsSponsorInUse={props.travelCostsSponsorInUse}
        sponsorHost={props.sponsorHost}
        sponsorHostSpecify={props.sponsorHostSpecify}
        sponsorOther={props.sponsorOther}
        sponsorOtherSpecify={props.sponsorOtherSpecify}
        travelCostsSponsor={props.travelCostsSponsor}
        travelCostSponsorOther={props.travelCostSponsorOther}
        clearFields={props.clearFields}
        handleChange={props.handleInput}
        oneColumn={props.oneColumn}
        isRequired={props.eucitizenFamily.value === false}
        uppercase
      />

      <FilledByPerson
        clearFields={props.clearFields}
        handleChange={props.handleInput}
        filledBySurname={props.filledBySurname}
        filledByFirstName={props.filledByFirstName}
        filledByAddress={props.filledByAddress}
        filledByEmail={props.filledByEmail}
        filledByPhonenumber={props.filledByPhonenumber}
        filledByOtherThanApplicant={props.filledByOtherThanApplicant}
        codesetSelectContainer={CodesetSelectContainer}
        oneColumn={props.oneColumn}
        uppercase
      />
    </>
  );
};

ContactsAndAccomodation.propTypes = {
  handleInput: PropTypes.func.isRequired,
  handleArrayChange: PropTypes.func.isRequired,
  clearFields: PropTypes.func.isRequired,
  validateFields: PropTypes.func.isRequired,
  initField: PropTypes.func.isRequired,
  eucitizenFamily: formFieldShapeFor(PropTypes.bool).isRequired,
  contactOrAccommodation: formFieldShape.isRequired,
  invitingOrganization: formFieldShapeFor(invitationFormShape).isRequired,
  invitingOrganizationContact: formFieldShapeFor(invitationFormShape).isRequired,
  invitingPersons: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  accommodations: formFieldShapeFor(PropTypes.arrayOf(invitationFormShape)).isRequired,
  oneDayTripWithoutAccommodation: formFieldShape.isRequired,
  travelCostsApplicantInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  travelCostsApplicant: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostApplicantOther: formFieldShape.isRequired,
  travelCostsSponsorInUse: formFieldShapeFor(PropTypes.bool).isRequired,
  sponsorHost: formFieldShape.isRequired,
  sponsorHostSpecify: formFieldShape.isRequired,
  sponsorOther: formFieldShape.isRequired,
  sponsorOtherSpecify: formFieldShape.isRequired,
  travelCostsSponsor: formFieldShapeFor(PropTypes.arrayOf(PropTypes.string)).isRequired,
  travelCostSponsorOther: formFieldShape.isRequired,
  filledByFirstName: formFieldShape.isRequired,
  filledBySurname: formFieldShape.isRequired,
  filledByAddress: formFieldShapeFor(formAddressShape).isRequired,
  filledByEmail: formFieldShape.isRequired,
  filledByPhonenumber: formFieldShape.isRequired,
  filledByOtherThanApplicant: formFieldShapeFor(PropTypes.bool).isRequired,
  codesetSelectContainer: PropTypes.elementType.isRequired,
  oneColumn: PropTypes.bool,
  showFaxnumber: PropTypes.bool,
};

ContactsAndAccomodation.defaultProps = {
  oneColumn: false,
  showFaxnumber: true,
};

export default ContactsAndAccomodation;
