import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import i18n from '../../../../services/i18n';
import HelpText from '../../TextWithHelp/HelpText';
import FileInput from './FileInput';

import './FileInputWithHelp.scss';

class FileInputWithHelp extends React.Component {
  state = {
    helpVisible: false,
  };

  render() {
    return (
      <div className={classnames('file-input-with-help',
        { 'file-input-with-help--with-bottom-margin': this.props.withBottomMargin },
        { 'file-input-with-help--with-top-margin': this.props.withTopMargin })}
      >
        <div className={classnames('file-input-with-help__upper-content', this.props.contentClassName)}>
          <FileInput
            value={this.props.value}
            name={this.props.name}
            describedBy={this.props.describedBy}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            accept={this.props.accept}
            showValue={this.props.showValue}
            autoFocus={this.props.autoFocus}
            className={this.props.className}
            icon={this.props.icon}
            buttonDisplayName={this.props.buttonDisplayName}
            dataCy={this.props.dataCy}
            multiple={this.props.multiple}
          />
          <Button
            icon={<Icon name="help_outline" ariaLabel={i18n.t('common:accessibleIconLabels.help')} />}
            class="invisible"
            className={classnames('file-input-with-help__button', this.props.buttonClassName)}
            onClick={() =>
              this.setState(prevState => ({
                helpVisible: !prevState.helpVisible,
              }))}
            tabIndex={-1}
          />
        </div>
        <HelpText
          helpId={this.props.helpId}
          helpText={this.props.help}
          helpVisible={this.state.helpVisible}
          helpTextVisibleClassName={this.props.helpTextVisibleClassName}
        />
      </div>
    );
  }
}

FileInputWithHelp.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  describedBy: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  accept: PropTypes.string,
  showValue: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
  buttonDisplayName: PropTypes.string,
  dataCy: PropTypes.string,
  multiple: PropTypes.bool,
  help: PropTypes.node.isRequired,
  helpId: PropTypes.string,
  withBottomMargin: PropTypes.bool,
  withTopMargin: PropTypes.bool,
  buttonClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  helpTextVisibleClassName: PropTypes.string,
};

FileInputWithHelp.defaultProps = {
  value: '',
  describedBy: '',
  disabled: false,
  accept: '',
  showValue: false,
  autoFocus: false,
  className: null,
  icon: 'cloud_upload',
  buttonDisplayName: i18n.t('common:selectFile'),
  dataCy: null,
  multiple: false,
  helpId: null,
  withBottomMargin: false,
  withTopMargin: false,
  buttonClassName: null,
  contentClassName: null,
  helpTextVisibleClassName: null,
};

export default FileInputWithHelp;
