import * as R from 'ramda';
import * as validators from './validators';
import { isUnderage, currentDateAsJsonString, addMonthsToDate } from '../datetime';
import { validatedIf, requiredIf, requiredTrueIf } from './validationRulesUtils';
import { flattenObject } from '../../utils/commonUtils';
import { aurnJsonDateStr } from '../../utils/aurnUtils';

export const maxStringLengths = {
  surname: 150,
  firstName: 150,
  surnameAtBirth: 150,
  formerSurnames: 150,
  placeOfBirth: 150,
  maritalStatusSpecify: 150,
  nationalIdentityNumber: 150,
  travelDocumentType: 100,
  travelDocumentNumber: 9,
  travelDocumentIssuedBy: 150,
  residencePermitNumber: 50,
  euTravelDocumentNumber: 50,

  street: 150,
  zip: 150,
  city: 150,
  phonenumber: 50,
  email: 100,
  occupationOther: 150,
  employerName: 150,
  companyName: 150,

  euFamilyRelationshipSpecify: 150,
  travelCostApplicantOther: 150,
  sponsorSpecify: 150,
  travelCostSponsorOther: 150,
  additionalJourneyInfo: 150,

  entryPermitNumber: 50,
  issuingAuthority: 150,

  journeyPurposeOther: 150,
  journeyPurposeDetails: 150,
};
export const minStringLengths = {
  travelDocumentNumber: 4,
};

export const referenceDate = (formValues) => {
  return R.defaultTo(
    // OVAF application has no date of request
    currentDateAsJsonString(),
    // VISA application has date of request
    // EDES application has no date of request - use AURN date which is "Date of submission"
    formValues.dateOfRequest || aurnJsonDateStr(formValues.aurn),
  );
};

const travelDocumentValidAfterDateOfRequestYears = (formValues) => {
  // 15 years for EDES/OVAF applications and 16 years for VISA applications
  return R.isNil(formValues.dateOfRequest) ? 15 : 16;
};

export const whenGuardianDataRequired = validatedIf((fieldValue, formValues) =>
  formValues.dateOfBirth && isUnderage(formValues.dateOfBirth, referenceDate(formValues)));

const whenGuardianAddressIsDifferentThanApplicants = validatedIf((fieldValue, formValues) =>
  formValues.dateOfBirth &&
  isUnderage(formValues.dateOfBirth, referenceDate(formValues)) &&
  formValues.guardianAddressIsDifferentThanApplicants);

const addressValidationRules = isRequired => ({
  street: [
    requiredIf(isRequired),
    validators.maxLength(maxStringLengths.street),
    validators.notOnlyWhitespace,
  ],
  zip: [
    validators.maxLength(maxStringLengths.zip),
    validators.notOnlyWhitespace,
  ],
  city: [
    requiredIf(isRequired),
    validators.maxLength(maxStringLengths.city),
    validators.notOnlyWhitespace,
  ],
  country: [
    requiredIf(isRequired),
  ],
});

const commonInvitationValidationRules = isRequired => ({
  invitationType: [
    requiredIf(isRequired),
  ],
  address: {
    rules: addressValidationRules(isRequired),
    validators: [requiredIf(isRequired)],
  },
  phonenumber: [
    requiredIf(isRequired),
    validators.maxLength(maxStringLengths.phonenumber),
    validators.notOnlyWhitespace,
  ],
  faxnumber: [
    validators.maxLength(maxStringLengths.phonenumber),
    validators.notOnlyWhitespace,
  ],
  email: [
    validators.maxLength(maxStringLengths.email),
    validators.emailFormat(),
  ],
});

export const occupationCodesWithOptionalAddress = [
  'NoOccupation',
  'NotApplicable',
  'Pensioner',
  'SelfEmployed',
];
export const occupationCodesWithOptionalPhonenumber = R.concat(
  occupationCodesWithOptionalAddress,
  ['Student'],
);

export const occupationAddressFieldsRequired = isRequired => validatedIf((fieldValue, formValues) =>
  R.not(R.isNil(formValues.occupationCode)) &&
  !R.includes(
    formValues.occupationCode,
    occupationCodesWithOptionalAddress,
  ))(requiredIf(isRequired));

export const occupationPhonenumberFieldRequired = isRequired =>
  validatedIf((fieldValue, formValues) =>
    R.not(R.isNil(formValues.occupationCode)) &&
    !R.includes(
      formValues.occupationCode,
      occupationCodesWithOptionalPhonenumber,
    ))(requiredIf(isRequired));

export const employerNameFieldRequired = isRequired => validatedIf((fieldValue, formValues) =>
  R.not(R.isNil(formValues.occupationCode)) &&
  formValues.occupationCode !== 'Student' &&
  !R.includes(
    formValues.occupationCode,
    occupationCodesWithOptionalAddress,
  ))(requiredIf(isRequired));

export const occupationAddressValidationRules = (isRequired) => {
  return {
    street: [
      occupationAddressFieldsRequired(isRequired),
      validators.maxLength(maxStringLengths.street),
      validators.notOnlyWhitespace,
    ],
    zip: [
      validators.maxLength(maxStringLengths.zip),
      validators.notOnlyWhitespace,
    ],
    city: [
      occupationAddressFieldsRequired(isRequired),
      validators.maxLength(maxStringLengths.city),
      validators.notOnlyWhitespace,
    ],
    country: [
      occupationAddressFieldsRequired(isRequired),
    ],
  };
};

export const secondaryAddressFieldsRequired = isRequired => validatedIf((fieldValue, formValues) =>
  R.compose(
    R.complement(R.isEmpty),
    R.reject(R.isNil),
    R.values,
    flattenObject(R.__, false),
  )(formValues.secondaryAddress))(requiredIf(isRequired));

export const secondaryAddressValidationRules = (isRequired) => {
  return {
    street: [
      secondaryAddressFieldsRequired(isRequired),
      validators.maxLength(maxStringLengths.street),
      validators.notOnlyWhitespace,
    ],
    zip: [
      validators.maxLength(maxStringLengths.zip),
      validators.notOnlyWhitespace,
    ],
    city: [
      secondaryAddressFieldsRequired(isRequired),
      validators.maxLength(maxStringLengths.city),
      validators.notOnlyWhitespace,
    ],
    country: [
      secondaryAddressFieldsRequired(isRequired),
      validators.notOnlyWhitespace,
    ],
  };
};

export const personalDetailsRules = ({ visibleDateFormat } = {}) => (isRequired) => {
  return {
    visaTypeRequested: [
      requiredIf(isRequired),
    ],
    surname: [
      requiredIf(isRequired),
      validators.maxLength(maxStringLengths.surname),
      validators.nameCharacters,
    ],
    firstName: [
      requiredIf(isRequired),
      validators.maxLength(maxStringLengths.firstName),
      validators.nameCharacters,
    ],
    surnameAtBirth: [
      requiredIf(isRequired),
      validators.maxLength(maxStringLengths.surnameAtBirth),
      validators.nameCharacters,
    ],
    dateOfBirth: [
      requiredIf(isRequired),
      validators.isValidPseudoDate(visibleDateFormat),
      validators.pseudoIsBefore(currentDateAsJsonString()),
    ],
    placeOfBirth: [
      requiredIf(isRequired),
      validators.maxLength(maxStringLengths.placeOfBirth),
      validators.notOnlyWhitespace,
    ],
    countryOfBirth: [
      requiredIf(isRequired),
    ],
    currentNationality: [
      requiredIf(isRequired),
    ],
    nationalityAtBirth: [],
    otherNationalities: [],
    sex: [
      requiredIf(isRequired),
    ],
    maritalStatus: [
      requiredIf(isRequired),
    ],
    maritalStatusSpecify: [
      validators.maxLength(maxStringLengths.maritalStatusSpecify),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => formValues.maritalStatus === 'Other')(validators.required),
    ],
    nationalIdentityNumber: [
      validators.notOnlyWhitespace,
      validators.maxLength(maxStringLengths.nationalIdentityNumber),
    ],
    guardianFirstName: [
      whenGuardianDataRequired(requiredIf(isRequired)),
      validators.maxLength(maxStringLengths.firstName),
      validators.nameCharacters,
    ],
    guardianSurname: [
      whenGuardianDataRequired(requiredIf(isRequired)),
      validators.maxLength(maxStringLengths.surname),
      validators.nameCharacters,
    ],
    guardianNationality: [
      whenGuardianDataRequired(requiredIf(isRequired)),
    ],
    guardianEmail: [
      validators.maxLength(maxStringLengths.email),
      validators.emailFormat(),
    ],
    guardianPhonenumber: [
      validators.maxLength(maxStringLengths.phonenumber),
      validators.notOnlyWhitespace,
    ],
    guardianAddress: {
      rules: {
        street: [
          whenGuardianAddressIsDifferentThanApplicants(requiredIf(isRequired)),
          validators.maxLength(maxStringLengths.street),
        ],
        zip: [
          validators.maxLength(maxStringLengths.zip),
          validators.notOnlyWhitespace,
        ],
        city: [
          whenGuardianAddressIsDifferentThanApplicants(requiredIf(isRequired)),
          validators.maxLength(maxStringLengths.city),
        ],
        country: [
          whenGuardianAddressIsDifferentThanApplicants(requiredIf(isRequired)),
        ],
      },
      validators: [],
    },
    secondaryGuardianFirstName: [
      validators.maxLength(maxStringLengths.firstName),
      validators.nameCharacters,
      validators.notOnlyWhitespace,
    ],
    secondaryGuardianSurname: [
      validators.maxLength(maxStringLengths.surname),
      validators.nameCharacters,
      validators.notOnlyWhitespace,
    ],
    secondaryGuardianNationality: [],
    secondaryGuardianEmail: [
      validators.maxLength(maxStringLengths.email),
      validators.emailFormat(),
    ],
    secondaryGuardianPhonenumber: [
      validators.maxLength(maxStringLengths.phonenumber),
      validators.notOnlyWhitespace,
    ],
    secondaryGuardianAddress: {
      rules: {
        street: [
          validators.maxLength(maxStringLengths.street),
          validators.notOnlyWhitespace,
        ],
        zip: [
          validators.maxLength(maxStringLengths.zip),
          validators.notOnlyWhitespace,
        ],
        city: [
          validators.maxLength(maxStringLengths.city),
          validators.notOnlyWhitespace,
        ],
      },
      validators: [],
    },
  };
};

export const travelDocumentRules = ({ visibleDateFormat } = {}) => (isRequired) => {
  return {
    travelDocumentType: [
      requiredIf(isRequired),
    ],
    travelDocumentTypeSpecify: [
      validators.maxLength(maxStringLengths.travelDocumentType),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => formValues.travelDocumentType === 'Other')(validators.required),
    ],
    travelDocumentNumber: [
      requiredIf(isRequired),
      validators.minLength(minStringLengths.travelDocumentNumber),
      validators.maxLength(maxStringLengths.travelDocumentNumber),
      validators.travelDocumentNumber,
    ],
    travelDocumentIssueDate: [
      requiredIf(isRequired),
      validators.date(visibleDateFormat),
      validators.isPastOrPresent,
      (fieldValue, formValues) => validators.isUpToTenYearsInThePast(
        fieldValue,
        referenceDate(formValues),
      ),
      validatedIf((fieldValue, formValues) => formValues.travelDocumentValidUntil)(
        (fieldValue, formValues) => validators.isUpToFifteenYearsDifference(
          fieldValue,
          formValues.travelDocumentValidUntil,
        ),
      ),
    ],
    travelDocumentValidUntil: [
      requiredIf(isRequired),
      validators.date(visibleDateFormat),
      validators.isFutureOrPresent,
      (fieldValue, formValues) => validators.isUpToSpecifiedYearsInTheFuture(
        fieldValue,
        referenceDate(formValues),
        travelDocumentValidAfterDateOfRequestYears(formValues),
      ),
      validatedIf((fieldValue, formValues) => formValues.travelDocumentIssueDate)(
        (fieldValue, formValues) => validators.isUpToFifteenYearsDifference(
          formValues.travelDocumentIssueDate,
          fieldValue,
        ),
      ),
    ],
    travelDocumentIssuedBy: [
      validators.notOnlyWhitespace,
      validators.maxLength(maxStringLengths.travelDocumentIssuedBy),
    ],
    travelDocumentCountryIssued: [
      requiredIf(isRequired),
    ],
  };
};

export const occupationAndRecidenceRules = ({ visibleDateFormat } = {}) => (isRequired) => {
  return {
    address: {
      rules: addressValidationRules(isRequired),
      validators: [requiredIf(isRequired)],
    },
    secondaryAddress: {
      rules: secondaryAddressValidationRules(isRequired),
    },
    formerSurnames: [
      validators.listFieldValidator([
        validators.maxLength(maxStringLengths.formerSurnames),
        validators.nameCharacters,
      ]),
    ],
    formerSurnamesInput: [
      validators.nameCharacters,
      validators.notOnlyWhitespace,
      validators.uniqueListFieldValue(['formerSurnames']),
      validators.maxLength(maxStringLengths.formerSurnames),
    ],
    phonenumbers: [
      requiredIf(isRequired),
      validators.arrayMaxLength(10),
      validators.listFieldValidator([
        validators.notOnlyWhitespace,
        validators.maxLength(maxStringLengths.phonenumber),
      ]),
    ],
    phonenumbersInput: [
      validators.notOnlyWhitespace,
      validators.uniqueListFieldValue(['phonenumbers']),
      validators.maxLength(maxStringLengths.phonenumber),
    ],
    email: [
      validators.maxLength(maxStringLengths.email),
      validators.emailFormat(),
    ],
    residenceInOtherCountry: [
      requiredIf(isRequired),
    ],
    residencePermitNumber: [
      validators.notOnlyWhitespace,
      validators.maxLength(maxStringLengths.residencePermitNumber),
    ],
    residencePermitValidUntil: [
      validators.date(visibleDateFormat),
      validators.isFutureOrPresent,
    ],
    occupationCode: [
      validatedIf((fieldValue, formValues) =>
        !formValues.eucitizenFamily)(requiredIf(isRequired)),
    ],
    occupationOther: [
      validators.maxLength(maxStringLengths.occupationOther),
      validators.notOnlyWhitespace,
    ],
    employerName: [
      validators.maxLength(maxStringLengths.employerName),
      validators.notOnlyWhitespace,
    ],
    schoolName: [
      validators.maxLength(maxStringLengths.employerName),
      validators.notOnlyWhitespace,
    ],
    employerAddress: {
      rules: addressValidationRules(false),
      validators: [],
    },
    employerPhonenumber: [
      validators.maxLength(maxStringLengths.phonenumber),
      validators.notOnlyWhitespace,
    ],
    employerEmail: [
      validators.maxLength(maxStringLengths.email),
      validators.emailFormat(),
    ],
  };
};

const whenEntryPermitRequired =
  validatedIf((fieldValue, formValues) => formValues.entryPermitForFinalDestination);

export const journeyDetailsRules = ({ visibleDateFormat } = {}) => (isRequired) => {
  return {
    entryPermit: {
      rules: {
        validFrom: [
          whenEntryPermitRequired(validators.date(visibleDateFormat)),
        ],
        validUntil: [
          whenEntryPermitRequired(validators.date(visibleDateFormat)),
          whenEntryPermitRequired(validators.isFutureOrPresent),
          whenEntryPermitRequired((fieldValue, formValues) =>
            validators.isAfterOrEqualDate(formValues.entryPermit.validFrom)(fieldValue)),
        ],
        issuingAuthority: [
          whenEntryPermitRequired(validators.maxLength(maxStringLengths.issuingAuthority)),
          validators.notOnlyWhitespace,
        ],
      },
    },

    borderOfFirstEntryCountry: [
      requiredIf(isRequired),
    ],
    dateOfArrival: [
      requiredIf(isRequired),
      validators.date(visibleDateFormat),
      (fieldValue, formValues) =>
        validators.isAfterOrEqualDate(
          referenceDate(formValues),
        )(fieldValue),
      (fieldValue, formValues) =>
        validators.isBeforeOrEqualDate(formValues.dateOfDeparture)(fieldValue),
      (fieldValue, formValues) => {
        const monthLimit = 6;

        const referenceDatePlusMonths =
          addMonthsToDate(
            referenceDate(formValues),
            monthLimit,
          );
        return validators.isBeforeOrEqualDate(referenceDatePlusMonths)(fieldValue);
      },
    ],
    dateOfDeparture: [
      requiredIf(isRequired),
      validators.date(visibleDateFormat),
      validators.isFutureOrPresent,
      (fieldValue, formValues) =>
        validators.isAfterOrEqualDate(formValues.dateOfArrival)(fieldValue),
    ],
    destinationStates: [
      requiredIf(isRequired),
    ],
    journeyPurposeOther: [
      validators.maxLength(maxStringLengths.journeyPurposeOther),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => (
        formValues.mainPurposeOfJourney === 'Other' ||
        R.includes('Other', formValues.otherPurposesOfJourney)
      ))(validators.required),
    ],
    journeyPurposeDetails: [
      validators.maxLength(maxStringLengths.journeyPurposeDetails),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) =>
        formValues.oneDayTripWithoutAccommodation)(requiredIf(isRequired)),
    ],
    mainPurposeOfJourney: [
      requiredIf(isRequired),
    ],
    numberOfEntriesRequested: [
      requiredIf(isRequired),
    ],

    fingerprintsAlreadyStored: [
      requiredIf(isRequired),
    ],
    fingerprintsStoredAt: [
      validators.date(visibleDateFormat),
      validators.isPastOrPresent,
    ],
    fingerprintsVisaNumber: [
      validators.cvisStickerNumber(),
    ],
  };
};

export const filledByAddressValidationRules = (isRequired) => {
  return {
    street: [
      validators.maxLength(maxStringLengths.street),
      validators.notOnlyWhitespace,
      validatedIf(
        (fieldValue, formValues) => formValues.filledByOtherThanApplicant,
      )(requiredIf(isRequired)),
    ],
    zip: [
      validators.maxLength(maxStringLengths.zip),
      validators.notOnlyWhitespace,
    ],
    city: [
      validators.maxLength(maxStringLengths.city),
      validators.notOnlyWhitespace,
      validatedIf(
        (fieldValue, formValues) => formValues.filledByOtherThanApplicant,
      )(requiredIf(isRequired)),
    ],
    country: [
      validators.notOnlyWhitespace,
      validatedIf(
        (fieldValue, formValues) => formValues.filledByOtherThanApplicant,
      )(requiredIf(isRequired)),
    ],
  };
};

export const contactsAndAccommodationRules = ({ visibleDateFormat } = {}) => (isRequired) => {
  return {
    contactOrAccommodation: [
      validators.invitationFilled(isRequired),
    ],
    invitingOrganization: {
      rules: {
        ...commonInvitationValidationRules(isRequired),
        companyName: [
          validators.maxLength(maxStringLengths.companyName),
          validators.notOnlyWhitespace,
          requiredIf(isRequired),
        ],
      },
    },
    invitingOrganizationContact: {
      rules: {
        ...commonInvitationValidationRules(false),
        surname: [
          validators.maxLength(maxStringLengths.surname),
          validators.nameCharacters,
          requiredIf(isRequired),
        ],
        firstNames: [
          validators.maxLength(maxStringLengths.firstName),
          validators.nameCharacters,
          requiredIf(isRequired),
        ],
      },
    },
    invitingPersons: {
      rules: {
        ...commonInvitationValidationRules(isRequired),
        surname: [
          validators.maxLength(maxStringLengths.surname),
          validators.nameCharacters,
          requiredIf(isRequired),
        ],
        firstNames: [
          validators.maxLength(maxStringLengths.firstName),
          validators.nameCharacters,
          requiredIf(isRequired),
        ],
      },
    },
    accommodations: {
      rules: {
        invitationType: [
          requiredIf(isRequired),
        ],
        address: {
          rules: addressValidationRules(false),
          validators: [requiredIf(false)],
        },
        phonenumber: [
          validators.maxLength(maxStringLengths.phonenumber),
          validators.notOnlyWhitespace,
        ],
        faxnumber: [
          validators.maxLength(maxStringLengths.phonenumber),
          validators.notOnlyWhitespace,
        ],
        email: [
          validators.maxLength(maxStringLengths.email),
          validators.emailFormat(),
        ],
        companyName: [
          validators.maxLength(maxStringLengths.companyName),
          validators.notOnlyWhitespace,
          requiredIf(isRequired),
        ],
      },
    },
    oneDayTripWithoutAccommodation: [
      validators.oneDayTripWithoutAccommodationAllowed(),
    ],
    euFirstname: [
      validators.maxLength(maxStringLengths.firstName),
      validators.nameCharacters,
      validatedIf((fieldValue, formValues) => formValues.eucitizenFamily)(requiredIf(isRequired)),
    ],
    euSurname: [
      validators.maxLength(maxStringLengths.surname),
      validators.nameCharacters,
      validatedIf((fieldValue, formValues) => formValues.eucitizenFamily)(requiredIf(isRequired)),
    ],
    euDateOfBirth: [
      validators.isValidPseudoDate(visibleDateFormat),
      validatedIf((fieldValue, formValues) => formValues.eucitizenFamily)(requiredIf(isRequired)),
    ],
    euNationality: [
      validatedIf((fieldValue, formValues) => formValues.eucitizenFamily)(requiredIf(isRequired)),
    ],
    euPassportNumber: [
      validators.maxLength(maxStringLengths.euTravelDocumentNumber),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => formValues.eucitizenFamily)(requiredIf(isRequired)),
    ],
    euFamilyRelationshipCode: [
      validatedIf((fieldValue, formValues) => formValues.eucitizenFamily)(requiredIf(isRequired)),
    ],
    euFamilyRelationshipSpecify: [
      validators.maxLength(maxStringLengths.euFamilyRelationshipSpecify),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => formValues.euFamilyRelationshipCode === 'OTHER')(validators.required),
    ],
    travelCostsApplicant: [
      validatedIf((fieldValue, formValues) => (
        formValues.travelCostsApplicantInUse
      ))(requiredIf(isRequired)),
    ],
    travelCostApplicantOther: [
      validators.maxLength(maxStringLengths.travelCostApplicantOther),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => (
        formValues.travelCostsApplicantInUse &&
        formValues.travelCostsApplicant.includes('Other')
      ))(validators.required),
    ],
    sponsorHost: [
      validatedIf(
        (fieldValue, formValues) => (
          formValues.travelCostsSponsorInUse && !formValues.sponsorOther
        ),
      )(requiredTrueIf(isRequired)),
    ],
    sponsorHostSpecify: [
      validators.maxLength(maxStringLengths.sponsorSpecify),
      validators.notOnlyWhitespace,
    ],
    sponsorOther: [
      validatedIf(
        (fieldValue, formValues) => (
          formValues.travelCostsSponsorInUse && !formValues.sponsorHost
        ),
      )(requiredTrueIf(isRequired)),
    ],
    sponsorOtherSpecify: [
      validators.maxLength(maxStringLengths.sponsorSpecify),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => (
        formValues.travelCostsSponsorInUse &&
        formValues.sponsorOther
      ))(validators.required),
    ],
    travelCostsSponsor: [
      validatedIf(
        (fieldValue, formValues) => formValues.travelCostsSponsorInUse,
      )(requiredIf(isRequired)),
    ],
    travelCostSponsorOther: [
      validators.maxLength(maxStringLengths.travelCostSponsorOther),
      validators.notOnlyWhitespace,
      validatedIf((fieldValue, formValues) => (
        formValues.travelCostsSponsorInUse &&
        formValues.travelCostsSponsor.includes('Other')
      ))(validators.required),
    ],
    filledByFirstName: [
      validators.maxLength(maxStringLengths.firstName),
      validators.nameCharacters,
      validatedIf(
        (fieldValue, formValues) => formValues.filledByOtherThanApplicant,
      )(requiredIf(isRequired)),
    ],
    filledBySurname: [
      validators.maxLength(maxStringLengths.firstName),
      validators.nameCharacters,
      validatedIf(
        (fieldValue, formValues) => formValues.filledByOtherThanApplicant,
      )(requiredIf(isRequired)),
    ],
    filledByEmail: [
      validators.maxLength(maxStringLengths.email),
      validators.emailFormat(),
      validatedIf(
        (fieldValue, formValues) => formValues.filledByOtherThanApplicant,
      )(requiredIf(isRequired)),
    ],
    filledByAddress: {
      rules: filledByAddressValidationRules(isRequired),
    },
    filledByPhonenumber: [
      validators.maxLength(maxStringLengths.phonenumber),
      validators.notOnlyWhitespace,
      validatedIf(
        (fieldValue, formValues) => formValues.filledByOtherThanApplicant,
      )(requiredIf(isRequired)),
    ],
  };
};
