import * as R from 'ramda';

import {
  combineRuleFunctionsFor,
  appendValidatorsToFields,
  requiredIf,
  requiredTrueIf,
  validatedIf,
} from 'visa-frontend-common/src/services/validator/validationRulesUtils';
import {
  contactsAndAccommodationRules,
  occupationAndRecidenceRules,
  journeyDetailsRules,
  personalDetailsRules,
  travelDocumentRules,
  employerNameFieldRequired,
  occupationAddressValidationRules,
  occupationPhonenumberFieldRequired,
  maxStringLengths as commonMaxStringLengths,
  whenGuardianDataRequired,
  referenceDate,
} from 'visa-frontend-common/src/services/validator/commonValidationRules';
import * as validators from 'visa-frontend-common/src/services/validator/validators';
import { minorChecklistItemTypes } from 'visa-frontend-common/src/components/ApplicationForm/commonApplicationModel';
import { addMonthsToDate, isUnderage, toDateOrNull } from 'visa-frontend-common/src/services/datetime';
import { isNilOrEmpty } from 'visa-frontend-common/src/utils/commonUtils';

import i18n from '../../services/i18n';

const visibleDateFormat = i18n.t('common:dateFormats.inputFormatLongDateDescription');

const whenDocumentsForMinorRequired = validatedIf((fieldValue, formValues) =>
  isNilOrEmpty(toDateOrNull(formValues.dateOfBirth)) ||
  isUnderage(formValues.dateOfBirth, referenceDate(formValues)));

const checklistSpecificRules = (isRequired) => {
  return {
    espLocation: [
      requiredIf(isRequired),
    ],
    checklistName: [
      requiredIf(isRequired),
    ],
    applicationChecklistItems: {
      rules: {
        applicantChecked: [
          (fieldValue, formValues, path) => {
            const checklistItemTypePath = R.compose(
              R.append('checklistItemType'),
              R.dropLast(1),
            );
            const documentForMinor = (_path, _formValues) => R.compose(
              R.includes(R.__, minorChecklistItemTypes),
              R.path(checklistItemTypePath(_path)),
            )(_formValues);

            return documentForMinor(path, formValues) ?
              whenDocumentsForMinorRequired(requiredIf(isRequired))(fieldValue, formValues, path) :
              requiredIf(isRequired)(fieldValue, formValues, path);
          },
        ],
      },
    },
  };
};

const guardianSpecificValidation = isRequired =>
  appendValidatorsToFields(
    [whenGuardianDataRequired(requiredIf(isRequired))],
    [
      ['guardianPhonenumber'],
      ['guardianEmail'],
    ],
  );

const occupationAndResidenceSpecificRules = (isRequired) => {
  return {
    phonenumbers: [
      requiredIf(isRequired),
      validators.arrayMaxLength(10),
      validators.listFieldValidator([
        validators.phoneNumber(),
      ]),
    ],
    employerName: [
      employerNameFieldRequired(isRequired),
      validators.notOnlyWhitespace,
      validators.maxLength(commonMaxStringLengths.employerName),
    ],
    schoolName: [
      validatedIf((fieldValue, formValues) => formValues.occupationCode === 'Student')(requiredIf(isRequired)),
      validators.maxLength(commonMaxStringLengths.employerName),
    ],
    employerAddress: {
      rules: occupationAddressValidationRules(isRequired),
      validators: [],
    },
    employerPhonenumber: [occupationPhonenumberFieldRequired(isRequired)],
  };
};

const journeyDetailsSpecificRules = (isRequired) => {
  return {
    dateOfArrival: [
      requiredIf(isRequired),
      validators.date(),
      (fieldValue, formValues) =>
        validators.isAfterOrEqualDate(
          referenceDate(formValues),
        )(fieldValue),
      (fieldValue, formValues) =>
        validators.isBeforeOrEqualDate(formValues.dateOfDeparture)(fieldValue),
      (fieldValue, formValues) => {
        const monthLimitByPurposeOfJourney = formValues.mainPurposeOfJourney === 'Seamen' ? 9 : 6;

        const referenceDatePlusMonths =
          addMonthsToDate(
            referenceDate(formValues),
            monthLimitByPurposeOfJourney,
          );

        return validators.isBeforeOrEqualDate(referenceDatePlusMonths)(fieldValue);
      },
    ],
  };
};

const contactsAndAccommodationSpecificRules = (isRequired) => {
  return {
    travelCostsApplicantInUse: [
      validatedIf((fieldValue, formValues) => {
        return !(formValues.travelCostsSponsorInUse || formValues.eucitizenFamily);
      })(requiredTrueIf(isRequired)),
    ],
    travelCostsSponsorInUse: [
      validatedIf((fieldValue, formValues) => {
        return !(formValues.travelCostsApplicantInUse || formValues.eucitizenFamily);
      })(requiredTrueIf(isRequired)),
    ],
  };
};

const generalAllowedCharactersAddressFields = [
  ['street'],
  ['zip'],
  ['city'],
];
const generalAllowedCharactersInvitationFields = [
  ...R.map(R.concat(['address', 'rules']), generalAllowedCharactersAddressFields),
  ['phonenumber'],
  ['faxnumber'],
  ['email'],
];
const generalAllowedCharactersFields = [
  // personal details
  ['surname'],
  ['firstName'],
  ['surnameAtBirth'],
  ['placeOfBirth'],
  ['maritalStatusSpecify'],
  ['nationalIdentityNumber'],
  ['guardianFirstName'],
  ['guardianSurname'],
  ...R.map(R.concat(['guardianAddress', 'rules']), generalAllowedCharactersAddressFields),
  ['guardianEmail'],
  ['secondaryGuardianFirstName'],
  ['secondaryGuardianSurname'],
  ...R.map(R.concat(['secondaryGuardianAddress', 'rules']), generalAllowedCharactersAddressFields),
  ['secondaryGuardianEmail'],
  // travel documents
  ['travelDocumentTypeSpecify'],
  ['travelDocumentNumber'],
  ['travelDocumentIssuedBy'],
  // Occupation and recidence
  ...R.map(R.concat(['address', 'rules']), generalAllowedCharactersAddressFields),
  ...R.map(R.concat(['secondaryAddress', 'rules']), generalAllowedCharactersAddressFields),
  ['email'],
  ['residencePermitNumber'],
  ['occupationOther'],
  ['employerName'],
  ['schoolName'],
  ...R.map(R.concat(['employerAddress', 'rules']), generalAllowedCharactersAddressFields),
  ['employerEmail'],
  // Journey details
  ['entryPermit', 'rules', 'issuingAuthority'],
  ['journeyPurposeOther'],
  ['journeyPurposeDetails'],
  ['fingerprintsVisaNumber'],
  // Contacts and accomodation
  ...R.map(R.concat(['invitingOrganization', 'rules']), [...generalAllowedCharactersInvitationFields, ['companyName']]),
  ...R.map(R.concat(['invitingOrganizationContact', 'rules']), [...generalAllowedCharactersInvitationFields, ['surname'], ['firstNames']]),
  ...R.map(R.concat(['invitingPersons', 'rules']), [...generalAllowedCharactersInvitationFields, ['surname'], ['firstNames']]),
  ...R.map(R.concat(['accommodations', 'rules']), [...generalAllowedCharactersInvitationFields, ['companyName']]),
  ['euFirstname'],
  ['euSurname'],
  ['euPassportNumber'],
  ['euFamilyRelationshipSpecify'],
  ['travelCostApplicantOther'],
  ['sponsorHostSpecify'],
  ['sponsorOtherSpecify'],
  ['travelCostSponsorOther'],
  ['filledByFirstName'],
  ['filledBySurname'],
  ...R.map(R.concat(['filledByAddress', 'rules']), generalAllowedCharactersAddressFields),
  ['filledByEmail'],
];

const allowedNameCharacterFields = [
  // personal details
  ['surname'],
  ['firstName'],
  ['surnameAtBirth'],
  ['formerSurnamesInput'],
  ['guardianFirstName'],
  ['guardianSurname'],
  ['secondaryGuardianFirstName'],
  ['secondaryGuardianSurname'],
  // Contacts and accomodation
  ...R.map(R.concat(['invitingOrganizationContact', 'rules']), [['surname'], ['firstNames']]),
  ...R.map(R.concat(['invitingPersons', 'rules']), [['surname'], ['firstNames']]),
  ['euFirstname'],
  ['euSurname'],
  ['filledByFirstName'],
  ['filledBySurname'],
];

const phoneNumberValidation = appendValidatorsToFields(
  [validators.phoneNumber()],
  [
    ['phonenumbersInput'],
    ['guardianPhonenumber'],
    ['secondaryGuardianPhonenumber'],
    ['employerPhonenumber'],
    ['invitingOrganization', 'rules', 'phonenumber'],
    ['invitingOrganizationContact', 'rules', 'phonenumber'],
    ['invitingPersons', 'rules', 'phonenumber'],
    ['accommodations', 'rules', 'phonenumber'],
    ['filledByPhonenumber'],
  ],
);

const nameCharactersValidation = appendValidatorsToFields(
  [validators.allowedNameCharacters],
  allowedNameCharacterFields,
);
const allowedCharactersValidation = appendValidatorsToFields(
  [validators.generalAllowedCharacters, validators.noLowercaseLetters],
  generalAllowedCharactersFields,
);

const formerSurnamesValidation = appendValidatorsToFields(
  [validators.listFieldValidator([
    validators.allowedNameCharacters,
    validators.noLowercaseLetters,
  ])],
  [['formerSurnames']],
);

const ruleFunctions = [
  personalDetailsRules({ visibleDateFormat }),
  travelDocumentRules({ visibleDateFormat }),
  occupationAndRecidenceRules({ visibleDateFormat }),
  journeyDetailsRules({ visibleDateFormat }),
  contactsAndAccommodationRules({ visibleDateFormat }),
  occupationAndResidenceSpecificRules,
  journeyDetailsSpecificRules,
  contactsAndAccommodationSpecificRules,
];

const ApplicationValidationRules = isRequired => R.compose(
  phoneNumberValidation,
  formerSurnamesValidation,
  nameCharactersValidation,
  allowedCharactersValidation,
  appendValidatorsToFields(
    [requiredIf(isRequired)],
    [['email']],
  ),
  guardianSpecificValidation(isRequired),
  combineRuleFunctionsFor([...ruleFunctions, checklistSpecificRules]),
)(isRequired);

export default ApplicationValidationRules;
