import PropTypes from 'prop-types';
import * as R from 'ramda';
import i18n from './services/i18n';
import { momentTimeUnit } from './services/datetime';

export const errorShape = PropTypes.exact({
  message: PropTypes.string.isRequired,
});

export const formTabShape = PropTypes.exact({
  isTouched: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
});

export const formTabDefaultProps = { isTouched: false, isValid: false };

export const locationShape = PropTypes.exact({
  pathname: PropTypes.string.isRequired,
  key: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  state: PropTypes.object,
});

export const historyShape = PropTypes.exact({
  length: PropTypes.number.isRequired,
  action: PropTypes.string.isRequired,
  location: locationShape.isRequired,
  block: PropTypes.func.isRequired,
  createHref: PropTypes.func.isRequired,
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  listen: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  index: PropTypes.number,
  entries: PropTypes.array,
  canGo: PropTypes.func,
});

export const organizationType = {
  MFA: 'MFA',
  POLICE: 'POLICE',
  BORDER: 'BORDER',
  CUSTOMS: 'CUSTOMS',
  OTHER: 'OTHER',
  SYSTEM: 'SYSTEM',
};

export const organizationSummaryShape = PropTypes.exact({
  organizationId: PropTypes.number.isRequired,
  countryCode: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  placeInFinnish: PropTypes.string.isRequired,
  organizationType: PropTypes.oneOf(R.keys(organizationType)),
  defaultCurrency: PropTypes.string,
  workAllocation: PropTypes.bool,
  missionInquiry: PropTypes.bool,
  pendingRequest: PropTypes.bool,
  stickerBarcode: PropTypes.bool,
});

export const permissionType = {
  ORGANIZATION: 'ORGANIZATION',
  GLOBAL: 'GLOBAL',
  OWNER: 'OWNER',
};

export const permissionShape = PropTypes.exact({
  permissionType: PropTypes.string.isRequired,
  authority: PropTypes.string.isRequired,
});

export const nameShape = PropTypes.exact({
  surname: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
});

export const addressShape = PropTypes.exact({
  street: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
});

export const userShape = PropTypes.exact({
  username: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  name: nameShape.isRequired,
  organization: organizationSummaryShape.isRequired,
  organizations: PropTypes.arrayOf(organizationSummaryShape),
  permissions: PropTypes.arrayOf(permissionShape).isRequired,
  pusername: PropTypes.string,
  decisionPrintingOffset: PropTypes.arrayOf(PropTypes.number),
  ctTestCaseId: PropTypes.string,
  multipleRootOrganizations: PropTypes.bool,
  missionUser: PropTypes.bool,
  validUntil: PropTypes.string,
  serviceCenterForEntryPermitsUser: PropTypes.bool,
});

export const userAccountShape = PropTypes.exact({
  username: PropTypes.string.isRequired,
  name: nameShape.isRequired,
});

export const userAccountWithStatusShape = PropTypes.exact({
  username: PropTypes.string.isRequired,
  name: nameShape.isRequired,
  enabled: PropTypes.bool.isRequired,
});

export const formFieldShapeFor = valueShape => PropTypes.exact({
  value: valueShape,
  isValid: PropTypes.bool.isRequired,
  validationError: PropTypes.string,
  isHidden: PropTypes.bool.isRequired,
});

export const formFieldShape = formFieldShapeFor(PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool,
  PropTypes.array,
  PropTypes.instanceOf(Object),
]));

export const formFieldDefaultProps = {
  value: null,
  isValid: false,
  validationError: null,
  isHidden: false,
};

export const formFileFieldDefaultProps = {
  value: null,
  fileName: null,
  isValid: false,
  validationError: null,
  isHidden: false,
};

export const formAddressDefaultProps = {
  street: formFieldDefaultProps,
  zip: formFieldDefaultProps,
  city: formFieldDefaultProps,
  country: formFieldDefaultProps,
};

export const formFileFieldShape = PropTypes.exact({
  value: PropTypes.instanceOf(File),
  fileName: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  validationError: PropTypes.string,
  isHidden: PropTypes.bool.isRequired,
});

export const formAddressShape = PropTypes.exact({
  street: formFieldShape,
  zip: formFieldShape,
  city: formFieldShape,
  country: formFieldShape,
});

export const formFieldDefaultValue = (value) => {
  return {
    ...formFieldDefaultProps,
    value,
  };
};

export const formArrayFieldDefaultProps = formFieldDefaultValue([]);

export const formArrayFileFieldShape = PropTypes.exact({
  file: formFileFieldShape,
});

export const codesetGroupEnum = PropTypes.oneOf([
  'AnnulmentGround',
  'AuthorityType',
  'AttachmentType',
  'ClosingGround',
  'Country',
  'CountryOfNationality',
  'CountryOfSubmission',
  'ExtensionGround',
  'FreeVisaReasonGround',
  'Gender',
  'LoweredVisaFeeReasonGround',
  'MainPurposesOfJourney',
  'MaritalStatus',
  'NumberOfEntries',
  'Occupation',
  'OfficialNote',
  'RefusalGround',
  'RevocationGround',
  'TravelDocumentType',
  'SponsorType',
  'TravelCostApplicantType',
  'TravelCostSponsorType',
  'VisaFee',
  'VisaType',
  'VisUserCountry',
  'Territory',
  'ReducedDurationGrounds',
  'CvisOperation',
  'CvisOperationVariant',
  'EuropeanUnionNationality',
  'CT512TravelDocumentTypeMRZ',
  'CT507FIReason',
  'CT503FPReason',
  'CT509FingerCode',
  'CT506FIDataSource',
  'CT502RefusalReason',
  'CT501ChangeGround',
  'CT510BilateralAgreement',
  'CT71PersonStatus',
  'Currency',
  'UmaOrganizationCode',
  'UmaVacCode',
  'UmaTravelDocumentType',
]);

export const matchShape = PropTypes.exact({
  url: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  isExact: PropTypes.bool.isRequired,
});

export const versionInfoShape = PropTypes.exact({
  api: PropTypes.string.isRequired,
  nvis: PropTypes.string.isRequired,
  ui: PropTypes.string.isRequired,
});

export const featureFlags = {
  CvisV3: 'CvisV3',
  CTtestCaseId: 'CTtestCaseId',
  MIDLinkVerification: 'MIDLinkVerification',
  EuropeanSearchPortal: 'EuropeanSearchPortal',
  OvafPending: 'OvafPending',
  CvisV3DecisionFormFields: 'CvisV3DecisionFormFields',
};

export const loginFlags = {
  LdapEnabled: 'LdapEnabled',
  OidcEnabled: 'OidcEnabled',
};

export const residenceOptions = [
  {
    type: 'CURRENT',
    text: i18n.t('common:applicationForm.occupationAndResidence.residenceOptions.CURRENT'),
  },
  {
    type: 'OTHER',
    text: i18n.t('common:applicationForm.occupationAndResidence.residenceOptions.OTHER'),
  },
];

export const visaFeeType = {
  FREE_OF_CHARGE: 'FreeOfCharge',
  LOWERED: 'Lowered',
};

export const timeUnitType = {
  TODAY: 'TODAY',
  CUSTOM: 'CUSTOM',
  ...R.pick([
    momentTimeUnit.DAY,
    momentTimeUnit.ISOWEEK,
    momentTimeUnit.MONTH,
    momentTimeUnit.YEAR,
  ], momentTimeUnit),
};

export const schengenCountryCodes = {
  AUT: 'AUT',
  BEL: 'BEL',
  BGR: 'BGR',
  CZE: 'CZE',
  DNK: 'DNK',
  EST: 'EST',
  FIN: 'FIN',
  FRA: 'FRA',
  DEU: 'DEU',
  GRC: 'GRC',
  HUN: 'HUN',
  HRV: 'HRV',
  ISL: 'ISL',
  ITA: 'ITA',
  LVA: 'LVA',
  LIE: 'LIE',
  LTU: 'LTU',
  LUX: 'LUX',
  MLT: 'MLT',
  NLD: 'NLD',
  NOR: 'NOR',
  POL: 'POL',
  PRT: 'PRT',
  ROU: 'ROU',
  SVK: 'SVK',
  SVN: 'SVN',
  ESP: 'ESP',
  SWE: 'SWE',
  CHE: 'CHE',
};

export const scanTypeEnum = {
  NORMAL: 'NORMAL',
  INFRARED: 'INFRARED',
  ULTRAVIOLET: 'ULTRAVIOLET',
};

export const scanTypePropType = PropTypes.oneOf([
  scanTypeEnum.NORMAL,
  scanTypeEnum.INFRARED,
  scanTypeEnum.ULTRAVIOLET,
]);

export const labelObject = PropTypes.exact({
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
});
